angular
    .module('annexaApp')
    .component('annexaBoxSecNotifications',{
        templateUrl: './components/sec/annexa-box-sec-notifications/annexa-box-sec-notifications.html',
        controller:['AnnexaFormlyFactory', 'NotificationFactory', '$scope', 'GlobalDataFactory', '$rootScope', function(AnnexaFormlyFactory, NotificationFactory, $scope, GlobalDataFactory, $rootScope) {
            var vm = this;
			vm.regOutputModule = [];
			vm.notificationModule = true;
			vm.boxTitle = 'global.literals.notifications';
            this.$onInit = function () {
                vm.regOutputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.output'").toArray();
                if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_notification_functionality){
                    vm.notificationModule = $rootScope.app.configuration.show_notification_functionality.value;
                }
            };
        }],
        bindings: {
            permissions: '=',
            content: '=',
            emptyText: '@',
            showSessionSecNotifications: '='
        }
    })
    .component('annexaBoxSecNotificationsRender', {
        templateUrl: './components/sec/annexa-box-sec-notifications/annexa-box-sec-notifications-render.html',
        controller: ['$state', 'NotificationFactory', 'Language', 'AnnexaFormlyFactory', 'DialogsFactory','$filter', 'RestService', '$scope', 'SecFactory', 'HelperService', '$rootScope', 'GlobalDataFactory', 'globalModals', function($state, NotificationFactory, Language, AnnexaFormlyFactory, DialogsFactory, $filter, RestService, $scope, SecFactory, HelperService, $rootScope, GlobalDataFactory, globalModals) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

			vm.notificationGlobalStatus = angular.copy(NotificationFactory.notificationGlobalStatus);
            vm.notificationGlobalStatus.unshift({id: 'NO_SEND', name: $filter('translate')('NO_SEND'), class: 'text-grey', icon: 'circle', style: 'text-grey'});
                
            vm.dossierTransactionStatus = [];
            vm.dossierTransactionStatus.push({id: 'NO_CREATED', name: $filter('translate')('NO_CREATED'), class: 'text-grey', icon: 'circle', style: 'text-grey'});
            vm.dossierTransactionStatus.push({id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS'), class: 'text-warn', icon: 'circle', style: 'text-warn'});
            vm.dossierTransactionStatus.push({id: 'FINISHED', name: $filter('translate')('FINISHED'), class: 'text-success', icon: 'circle', style: 'text-success'});
            
            vm.proposalId = (vm.content && vm.content[0] && vm.content[0].prop && vm.content[0].prop.id)?vm.content[0].prop.id:undefined;
            
            vm.viewNotification = function (notificationId) {
                 RestService.findOne('Notification', notificationId).then(function (data) {
                        var decodedData = JSOG.decode(data.data);
                    	var notificationViewModal = NotificationFactory.notificationViewModal(decodedData);

                        AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);    
                    }).catch(function (error) {
                    	DialogsFactory.error('global.sec.literals.canNotOpenNotification')
                    });
            }

		    vm.getIconClassNotificationStatus = function (status) {
                switch (status) {
                    case 'PENDING':
                        return 'text-warn';
                    case 'SEND':
                        return 'text-info';
                    case 'COMPLETE':
                        return 'text-success';
                    case 'ERROR':
                        return 'text-danger';
                }

                return 'text-grey';
            }

			vm.getNameNotificationStatus = function (status) {
                switch (status) {
                    case 'PENDING':
                        return  $filter('translate')('PENDING');
                    case 'SEND':
                        return $filter('translate')('global.literals.SEND');
                    case 'COMPLETE':
                        return $filter('translate')('global.literals.COMPLETE');
                    case 'ERROR':
                        return $filter('translate')('global.literals.ERROR');
                }

                return $filter('translate')('NO_SEND');
            }

			vm.getIconClassTramStatus = function (dt) {
				if(dt && dt.id){
					if(dt.endDate){
						return 'success';
					}else{
						return 'warn';
					}
				}else{
					return 'text-grey';
				}
            }

			vm.getNameTramStatus = function (dt) {
                if(dt && dt.id){
					if(dt.endDate){
						return $filter('translate')('FINISHED');
					}else{
						return $filter('translate')('IN_PROGRESS');
					}
				}else{
					return $filter('translate')('NO_CREATED');
				}
            }
			
			vm.createNotifications = function(){
				var createNotificationsModal = function(secNotOk){
					if(secNotOk != null && secNotOk.length > 0){
						var createNotificationsModalFinal = function(secNotOk){
							var steps = [];
							_.forEach(secNotOk, function(sn, index){
								var validDocStatus = ["SIGNED","COMPLETE"];
				        		var validRelationType = ["DOCUMENT","WORK_DOCUMENT"];
					        	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.notification_valid_document_status) {
					        		validDocStatus = $rootScope.app.configuration.notification_valid_document_status.status;
					        		validRelationType = $rootScope.app.configuration.notification_valid_document_status.relation_type;
					        	}
					        	var decreeDocumentType = $rootScope.app.configuration.sec_document_types["SDR"];
				        		var documentsWithSNot = [];
								var selectedDocuments = [];
								var thirdsWithSNot = [];
								var selectedThirds = [];
								var thirdsEnabled = {};
								var dtWithoutThirds = [];
				            	var documentsIdSelected = {};
				            	var certDocuments = [];
				            	var decreeDocuments = [];
								if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && sn.relatedProposal.notificationTransaction.dossier.thirds && sn.relatedProposal.notificationTransaction.dossier.thirds.length > 0){
									thirdsWithSNot = $linq(sn.relatedProposal.notificationTransaction.dossier.thirds).where("x => x.notifiable === true").toArray();
									if(thirdsWithSNot && thirdsWithSNot.length > 0){
										_.forEach(thirdsWithSNot, function(thwsn){
											selectedThirds.push(thwsn.third.id);
										});
									}
								}
								if(sn.allDocuments && sn.allDocuments.length > 0){
									_.forEach(sn.allDocuments, function(doc){
									if(_.contains(validDocStatus, doc.document.docStatus) && _.contains(validRelationType, doc.relationType) && doc.document.type && doc.document.type.includeNotification && !doc.document.physical){
											doc.document.dossierTransactionDocumentId = doc.id;
											if(doc.certificateFromThisProposal){
												doc.document.name = ((doc.document.name)?doc.document.name:'')+' ('+sn.relatedProposal.proposalNumber+')';
												certDocuments.push(doc);
											}
											if(_.contains($rootScope.app.configuration.sec_document_types["SDR"].id, doc.document.type.id.toString())){
												doc.document.name = ((doc.document.name)?doc.document.name:'')+' ('+sn.relatedProposal.proposalNumber+')';
												decreeDocuments.push(doc);
											}
											documentsWithSNot.push(doc.document);
											if(!doc.dossierThird || !doc.dossierThird.third || !doc.dossierThird.third.id){
												dtWithoutThirds.push(doc);
											}
											if(doc.generateFromSecNotification){
												selectedDocuments.push(doc);
											}
										}
									});
								}
								if(thirdsWithSNot && thirdsWithSNot.length > 0 && sn.allDocuments){
									_.forEach(thirdsWithSNot, function(third){
										var docs = $linq(sn.allDocuments).distinctBy("x => x.document.id").where("x => x.document.type.includeNotification == true && x.dossierThird && x.dossierThird.id == "+third.id).select("x => x.document.id").toArray();
										thirdsEnabled[third.id] = {enabled:true, docs:docs};
									});
								}
								if(sn.relatedProposal && sn.relatedProposal.proposalNotificationThirdConfiguration && sn.relatedProposal.proposalNotificationThirdConfiguration.length > 0) {
				            		_.forEach(sn.relatedProposal.proposalNotificationThirdConfiguration, function(pntc){
										documentsIdSelected[pntc.dossierThird.id] = pntc.proposalNotificationThirdDocumentConfiguration;
										if(certDocuments && certDocuments.length > 0) {
											_.forEach(certDocuments, function(cd){
												documentsIdSelected[pntc.dossierThird.id].push(cd);
											});
										}
										if(decreeDocuments && decreeDocuments.length > 0) {
											_.forEach(decreeDocuments, function(dcd){
												documentsIdSelected[pntc.dossierThird.id].push(dcd);
											});
										}
									});
				            		_.forEach(thirdsWithSNot, function(third){
										third.selected = false;
										if(documentsIdSelected && documentsIdSelected[third.id]) {
											third.selected = true;
										}
									});
				            	} else {
				            		_.forEach(thirdsWithSNot, function(third){
				            			documentsIdSelected[third.id] = selectedDocuments;
									});
				            	}
								var sections = [];
								if(HelperService.isSuperAdmin()) {
					            	sections = GlobalDataFactory.sections;
					            } else {
					            	sections = $linq($rootScope.LoggedUser.userSections).select("x => x.section").toArray();
					            }
								var showTramClassification = false;
					            var requiredTramClassification = false;
					            if($rootScope.app.configuration.tram_classification_register_output) {
					        		requiredTramClassification = $rootScope.app.configuration.tram_classification_register_output.required;
					        		showTramClassification = $rootScope.app.configuration.tram_classification_register_output.show;
					        	}
					            if(showTramClassification) {
					            	var defaultTramClassification = undefined;
					            	if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && sn.relatedProposal.notificationTransaction.dossier.tramClassification) {
					            		defaultTramClassification = sn.relatedProposal.notificationTransaction.dossier.tramClassification;
					            	}
					            }	
								var step = {
									title: $filter('translate')('global.literals.new_notification_title')+': '+sn.relatedProposal.proposalNumber+' '+sn.relatedProposal.extract,
									key: 'not'+sn.relatedProposal.id+'_'+index,
									disabled: false,
									disableNext: function(model, wizard) {
										var selected = false;
										if(wizard.steps[index].annexaFormly.form.$valid){
											selected = true;
										}
										return !selected;
									},
									annexaFormly: {
										fields: [
											{
				                                key: 'thirds',
				                                type: 'annexaComponent',
				                                templateOptions: {
				                                    type: 'annexa-select-thirds-documents',
				                                    first: true
				                                },
				                                data: {
				                                    title: 'global.literals.receivers',
							                        thirds: thirdsWithSNot,
													removeAddressesWithoutSelectedNotificationType: true,
													thirdsEnabled: thirdsEnabled,
													selectedThirds: selectedThirds,
							                        titleDocuments: 'global.publishSite.literals.attachedDocuments',
							                        documents: documentsWithSNot,
							                        documentsIdSelected: documentsIdSelected
				                                }            
											},
											{
				                                key: 'section',
				                                type: 'annexaSelectRow',
				                                className: 'col-sm-12',
				                                data: {
				                                    informed:true,
				                                    row:true,
				                                    clear: function($event,model,key, $select) {
				                                        $event.stopPropagation();
				                                        model[key] = undefined;
				                                        if($select) {
				                                            $select.selected = undefined;
				                                            $select.search = undefined;
				                                        }
				                                    }
				                                },
				                                templateOptions: {
				                                    optionsAttr: 'bs-options',
				                                    ngOptions: 'option in to.options | filter: $select.search',
				                                    label: 'global.literals.section',
				                                    valueProp: 'id',
				                                    labelProp: vm.languageColumn,
				                                    placeholder: '',
				                                    options: ((sections && sections.length > 0)?new SortedArray(sections, vm.languageColumn).sort():[]),
				                                    required: true,
				                                    focus: false
				                                },
				                                hideExpression: function($viewValue, $modelValue, scope) {
				                                	if(sections && sections.length === 1){
														scope.model.section = sections[0];
													}
													return (sections && sections.length === 1);
				                                }
				                            },
											{
				                                key: 'tramClassification',
				                                type: 'annexaSelectRow',
				                                className: 'col-sm-12',
				                                data: {
				                                    informed:true,
				                                    row:true,
				                                    clear: function($event,model,key, $select) {
				                                        $event.stopPropagation();
				                                        model[key] = undefined;
				                                        if($select) {
				                                            $select.selected = undefined;
				                                            $select.search = undefined;
				                                        }
				                                    }
				                                },
				                                templateOptions: {
				                                    optionsAttr: 'bs-options',
				                                    ngOptions: 'option in to.options | filter: $select.search',
				                                    label: 'global.literals.tramClassification',
				                                    valueProp: 'id',
				                                    labelProp: vm.languageColumn,
				                                    placeholder: '',
				                                    options: ((GlobalDataFactory.tramClassifications && GlobalDataFactory.tramClassifications.length > 0)?new SortedArray(GlobalDataFactory.tramClassifications, vm.languageColumn).sort():[]),
				                                    required: requiredTramClassification,
				                                    focus: false, 
													defaultValue: defaultTramClassification
				                                },
				                                hideExpression: function($viewValue, $modelValue, scope) {
													return !showTramClassification;
				                                }
											}
										],
										model:{}
									},
									options:{},
									secNotification: sn
								}
								steps.push(step);
							});
							if(steps && steps.length > 0){
								var finishCreateNotifications = function(){
									var selfFinishCreateNotifications = this;
									var sendNotifications = {};
									if(this.wizard && this.wizard.steps){
										var notsOk = true;
										_.forEach(this.wizard.steps, function(step){
											if(notsOk && step && step.secNotification && step.secNotification.relatedProposal && step.secNotification.relatedProposal.id && step.secNotification.relatedProposal.notificationTransaction && step.secNotification.relatedProposal.notificationTransaction.dossier && step.secNotification.relatedProposal.notificationTransaction.dossier.id){
												if(!sendNotifications[step.secNotification.relatedProposal.id]){
													sendNotifications[step.secNotification.relatedProposal.id] = [];
												}
												var thsToN = [];
												if(step.annexaFormly && step.annexaFormly.fields){
													var fieldThirds = $linq(step.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'thirds'");
													if(fieldThirds) {
														var selectedThirds = $linq(fieldThirds.data.thirds).where("x => x.selected").toArray();
									                    if(selectedThirds && selectedThirds.length > 0) {
															var addressesSelected = [];
															_.forEach(selectedThirds, function(item) {
									                            var selectedAddress = $linq(item.addressesOrig).firstOrDefault(undefined, "x => x.selected");
									                            if(selectedAddress) {
									                                addressesSelected.push({ address: selectedAddress, third: ((item.third)?item.third:{id:item.id}), dossierId:((item.dossier && item.dossier.id)?item.dossier.id:undefined)});
									                            }
									                        });
									                        if(addressesSelected.length == selectedThirds.length) {
																thsToN = addressesSelected;
									                        }
														}
													}
												}
												if(!thsToN || thsToN.length === 0){
													notsOk = false;
													var fieldThirds = $linq(step.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'thirds'");
													if(fieldThirds) {
														var selectedThirds = $linq(fieldThirds.data.thirds).where("x => x.selected").toArray();
									                    if(selectedThirds && selectedThirds.length > 0) {
															selfFinishCreateNotifications.alerts.push({ msg: 'global.errors.channelNoValidSendNotifications' });
														}else{
															selfFinishCreateNotifications.alerts.push({ msg: 'global.errors.noDestinataris' });
														}
													}
												}
												var docsToN = [];
												if(step.annexaFormly && step.annexaFormly.fields){
							                        if(selectedThirds && selectedThirds.length > 0) {
							                            _.forEach(selectedThirds, function(thi){
							                                  var documentsSelected = $linq(thi.documentsToSelect).where("x => x.selected").toArray();
							                                  if(notsOk && (!documentsSelected || documentsSelected.length == 0)) {
							                                	  notsOk = false;
							                                	  selfFinishCreateNotifications.alerts.push({msg: 'global.publishSite.errors.noThirdDocumentSelected'});
							                                  } else {
							                                	  _.forEach(documentsSelected, function(docSelected){
							                                		  docsToN.push({
							                                			  documentId:docSelected.id,
							                                			  thirdId:thi.third.id,
							                                			  dossierTransactionDocumentId:docSelected.dossierTransactionDocumentId
							                                		  });
							                                	  });
							                                  }
							                              });
							                        }
												}
												if(notsOk && (!docsToN || docsToN.length === 0)){
													notsOk = false;
													selfFinishCreateNotifications.alerts.push({ msg: 'global.errors.noDocuments' });
												}
												if(notsOk){
													thsToN = angular.copy(thsToN);
													var docsToNAux = [];
													_.forEach(docsToN,function(dtn){
														docsToNAux.push({
															document:{id:dtn.documentId}, 
															third:{id:dtn.thirdId},
															dossierTransactionDocumentId:dtn.dossierTransactionDocumentId
														});
													});
										            
													_.forEach(thsToN, function(item){
														var status = undefined;
								                        if(item && item.address && item.address.selectedNotificationType && item.address.selectedNotificationType.statuses && item.address.selectedNotificationType.statuses.length > 0){
								                        	status = item.address.selectedNotificationType.statuses[0];
								                        }
								                        var valueAlertAddress = undefined;
								                        if(item.address.selectedNotificationType.selectedAlertAddress && item.address.selectedNotificationType.selectedAlertAddress.id != 0) {
								                        	var alertAddress = item.address.selectedNotificationType.selectedAlertAddress;
								                        	if(alertAddress && alertAddress.addressType && alertAddress.addressType == 'POSTAL' && alertAddress.address) {
								                        		valueAlertAddress = alertAddress.address[($rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value?'address':'shortAddress')+Language.getActiveColumn()];
								                        	} else if(alertAddress){
								                        		valueAlertAddress = (alertAddress.phoneInternationalPrefix?alertAddress.phoneInternationalPrefix:'') + alertAddress.telematicValue;
								                        	}
								                        }
								                    	delete item.address.selectedNotificationType.selectedAlertAddress;
								                    	delete item.address.selectedNotificationType.alertAddresses;
														var sendNotification = {
															secNotification:angular.copy(step.secNotification),
															documents:angular.copy(docsToNAux),
															thirds: [{
																third: {id:item.third.id},
																address: ((item.address && item.address.selectedNotificationType && item.address.selectedNotificationType.addressType == 'OTHER')?undefined:{id:item.address.id}), 
                            									statuses: [((status && status.id)?{createdDate: new Date(), status: {id:status.id}}:undefined)],
                            									telematicNumber: valueAlertAddress,
																dossierId: item.dossierId
															}],
															notificationType: ((item.address && item.address.selectedNotificationType)?{id:item.address.selectedNotificationType.id}:undefined),
															section: ((step.annexaFormly && step.annexaFormly.model && step.annexaFormly.model.section && step.annexaFormly.model.section.id)?{id:step.annexaFormly.model.section.id}:undefined),
															tramClassification: ((step.annexaFormly && step.annexaFormly.model && step.annexaFormly.model.tramClassification && step.annexaFormly.model.tramClassification.id)?{id:step.annexaFormly.model.tramClassification.id}:undefined)
														}
														if(sendNotification.secNotification){
															sendNotification.secNotification.allDocuments = undefined;
														}
													  	sendNotifications[step.secNotification.relatedProposal.id].push(JSOG.encode(sendNotification));
													});
												}
											}
										});
										if(notsOk){
											SecFactory.createNotificationsSecNotificationDocuments(vm.proposalId, sendNotifications).then(function(dataCall, status) {
												if(dataCall){
													SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okNot', 'global.sec.literals.koNot', true);
													selfFinishCreateNotifications.close();
												} else {
													selfFinishCreateNotifications.close();
													$state.reload();
												}
											}).catch(function(error) {
												DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
									        });
										}
									}
								}
								var modalCreateNotifications = angular.copy(globalModals.secNotificationCreateNotifications);
								modalCreateNotifications.wizard.steps = steps;
								modalCreateNotifications.wizard.modal = modalCreateNotifications;
								AnnexaFormlyFactory.showModal('fromScannerWizardModal', modalCreateNotifications, finishCreateNotifications, false, true);
							}
						}
						var propsWithSons = $linq(secNotOk).where("x => x.relatedProposal && x.relatedProposal.workWithDossierSons").select("x => x.relatedProposal.id").toArray();
						if(propsWithSons && propsWithSons.length > 0){
							SecFactory.getDossierThirdsAndSons(propsWithSons, undefined).then(function(dataThirdsAndSons, status) {
								var secNotOkAux = []; 
								_.forEach(secNotOk, function(sn){
									var snAux = angular.copy(sn);
									if(sn.relatedProposal && sn.relatedProposal.id && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && dataThirdsAndSons[sn.relatedProposal.id]){
										snAux.relatedProposal.notificationTransaction.dossier.thirds = dataThirdsAndSons[sn.relatedProposal.id];
									}
									secNotOkAux.push(snAux);
								});
								createNotificationsModalFinal(secNotOkAux);
							}).catch(function(error) {
								createNotificationsModalFinal(secNotOk);
					        });		
						}else{
							createNotificationsModalFinal(secNotOk);
						}
					}
				}
				
				SecFactory.getSecNotificationsConfigurationProposal("NOTIFICATION", vm.proposalId).then(function(data, status) {
					var dataAux =data;
					if(dataAux && dataAux.length > 0){
						createNotificationsModal(dataAux)
					}else{
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
					}
				}).catch(function(error) {
					DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
		        });
			}
			
			vm.completeDossierTransactions = function(){
				var createDossierTransactions = function(secNotOk){
					if(secNotOk != null && secNotOk.length > 0){
						var secNotNotExistUser = $linq(secNotOk).where(function(x){
							if(x.transactions && $linq(x.transactions).firstOrDefault(undefined, "x => !x.existUser")){
								return true;
							}else{
								return false;
							}
						}).toArray();
						if(!secNotNotExistUser || (secNotNotExistUser && secNotNotExistUser.length == 0)){
							var message = $filter('translate')('global.sec.literals.secNotificationTransferClose')+'</br>';
							_.forEach(secNotOk, function(sn, index){
								message += '&nbsp;&nbsp;&nbsp;&nbsp;'+$filter('translate')('global.sec.literals.proposal')+': '+sn.relatedProposal.proposalNumber+'</br>';
							});
							DialogsFactory.confirm('global.sec.literals.secNotificationTransferCloseTitle', message)
	        	            .then(function (data) {
        						SecFactory.createNotificationsSecTransactions(vm.proposalId, JSOG.encode(secNotOk)).then(function(dataCall, status) {
									if(dataCall){
										SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okTrans', 'global.sec.literals.koTrans', true);
									} else {
										$state.reload();
									}
								}).catch(function(error) {
									DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
								});
				           }).catch(function (data) {
	        		       });
						}else{
							var finishedSelectUsers = function(){
								var selfFinishedSelectUsers = this;
								_.forEach(secNotOk, function (value) {
									if(value.transactions && selfFinishedSelectUsers.annexaFormly && selfFinishedSelectUsers.annexaFormly.model){
										_.forEach(value.transactions, function (value2, index) {
											if(value.relatedProposal && value.relatedProposal.id && !value2.existUser){
												if(selfFinishedSelectUsers.annexaFormly.model && selfFinishedSelectUsers.annexaFormly.model[index+"_User_"+value.relatedProposal.id] && selfFinishedSelectUsers.annexaFormly.model[index+"_User_"+value.relatedProposal.id].id){
													value2.responsibleUser = {id:selfFinishedSelectUsers.annexaFormly.model[index+"_User_"+value.relatedProposal.id].id};
												}
											}
										});
									}
								});
								SecFactory.createNotificationsSecTransactions(vm.proposalId, JSOG.encode(secNotOk)).then(function(dataCall, status) {
									if(dataCall){
										SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okTrans', 'global.sec.literals.koTrans', true);
										selfFinishedSelectUsers.close();
									} else {
										selfFinishedSelectUsers.close();
										$state.reload();
									}
								}).catch(function(error) {
									DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
								});
				           }
							var modalSelectUsers = angular.copy(globalModals.secNotificationResponsibleUsers);
							modalSelectUsers.annexaFormly.model = {};
							_.forEach(secNotNotExistUser, function (value) {
								if(value.transactions){
									_.forEach(value.transactions, function (value2, index) {
										if(value.relatedProposal && value.relatedProposal.id && !value2.existUser){
				                        	modalSelectUsers.annexaFormly.model[index+"_Profile_"+value.profile.id] = value.profile.id;
											modalSelectUsers.annexaFormly.model[index+"_User_"+value.relatedProposal.id] = undefined;
											var field = {
							                    key: index+"_User_"+value.relatedProposal.id,
							                    type: 'annexaSelectRow',
							                    className: 'col-sm-12',
							                    data: {
							                        informed:true,
							                        row:true,
							                        colClass: '',
							                        labelClass: 'label-strong',
							                        clear: function($event,model,key, $select) {
							                            $event.stopPropagation();
							                            model[key] = undefined;
							                            if($select) {
							                                $select.selected = undefined;
							                                $select.search = undefined;
							                            }
							                        }
							                    },
							                    templateOptions: {
							                        optionsAttr: 'bs-options',
							                        ngOptions: 'option in to.options | filter: $select.search',
							                        label: $filter('translate')('global.literals.responsibleUser')+" "+((value.relatedProposal && value.relatedProposal.proposalNumber)?value.relatedProposal.proposalNumber:'')+" - "+((value2.type && value2.type[vm.languageColumn])?value2.type[vm.languageColumn]:''),
							                        valueProp: 'id',
							                        labelProp: 'completeName',
							                        placeholder: '',
							                        options: [],
							                        required: true,
							                        focus: false
							                    },
							                    controller: ['$scope', '$filter', 'Language', 'GlobalDataFactory', 'SecFactory', function($scope, $filter, Language, GlobalDataFactory, SecFactory) {
				                                     $scope.to.options = [];
													 if($scope.model && $scope.model[index+"_Profile_"+value.profile.id]){
														var profile = $linq(GlobalDataFactory.allProfiles).firstOrDefault(undefined, "x => x.id == "+$scope.model[index+"_Profile_"+value.profile.id]);
														if(profile){
															SecFactory.getUserProfiles(profile.id).then(function(data){
																if(data && data.length > 0){
																	$scope.to.options = data;
																}
															}).catch(function(error){});
														}	
													 }
				                                }]
							                }
											modalSelectUsers.annexaFormly.fields.push(field);
										}
									});
								}
	                        });
							AnnexaFormlyFactory.showModal('fromScannerWizardModal', modalSelectUsers, finishedSelectUsers, false);
						}
					}
				}
				
				SecFactory.getSecNotificationsConfigurationProposal("CLOSE_TRANSFER", vm.proposalId).then(function(data, status) {
					var dataAux =data;
					if(dataAux && dataAux.length > 0){
						createDossierTransactions(dataAux)
					}else{
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
					}
				}).catch(function(error) {
					DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
		        });
			}
			
			$scope.$on('updateNotificationState', function(event, args) {
				if(args && args.id && args.globalStatus){
					_.forEach(vm.content, function(cont){
						if(cont.not && cont.not.id == args.id){
							cont.not.globalStatus = args.globalStatus;
						}
					});
				}
	        });
                                    

        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@',
            showSessionSecNotifications: '='
        }
    })