angular
    .module('annexaApp')
    .component('annexaBoxObjectProposal',{
        templateUrl: './components/sec/annexa-box-object-proposal/annexa-box-object-proposal.html',
        controller: ['SecFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'HeaderService', 'SecModals', 'DccumentsFactory', '$http', 'TramNewFactory', function (SecFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, HeaderService, SecModals, DccumentsFactory, $http, TramNewFactory) {
            //region General 
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            vm.loggedUser = $rootScope.LoggedUser;
            vm.canEdit = ((vm.proposal && vm.proposal.state && _.contains(['PENDING', 'ACCEPTED'],vm.proposal.state))?true:false);
			vm.canAddedSecConfiguration = SecFactory.canAddedSecConfiguration;
			vm.secretaryProfile = GlobalDataFactory.secretaryProfile;
    		if(!vm.secretaryProfile){
    			vm.secretaryProfile = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.secretary_profile);
    		}
            vm.isActiveCouncillor = false;		
            if(vm.canEdit && vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE' && vm.proposal.state == 'ACCEPTED'){
            	vm.canEdit = false;
            }
            //region Form

            vm.alerts = [];

            //endregion

            //region Organs Box
            $scope.$on('annexaBoxOrgansAddOpinionOrgan', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-proposal' && vm.isEdit) {
                	if(args.organ && args.organ && args.modal){
                		SecFactory.addProposalOrgan(vm.proposal.id, args.organ, args.delegationType).then(function(data) {
	                        if(data) {
	                        	 vm.organsBox.content.push(data);
	                        }
	                        args.modal.close();
	                    }).catch(function(error) {
	                    	if(error && error.data && error.data.message === 'Organ inactive'){
								args.modal.alerts.push({ msg: $filter('translate')('global.errors.inactiveOrgan') });
							}else{
								args.modal.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'addOrgan', error)});
							}
	                    })
	                }else{
	            		DialogsFactory.error('global.sec.literals.error_required_files_organ');
	            	}
                }
            });
            
    		$scope.$on('annexaBoxOrgansModifyOpinionOrgan', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-proposal' && vm.isEdit) {
                	if(args.organ && args.organ.id && args.organ.organ && args.organ.organ.id){
	                	SecFactory.updateProposalOrgan(vm.proposal.id, args.organ.id, args.organ.organ.id, ((args.organ.delegationType && args.organ.delegationType.id)?args.organ.delegationType.id:undefined)).then(function(data) {
	                		if(data) {
		                		var index = $linq(vm.organsBox.content).indexOf("x => x.id == " + data.id);
		                        if(index != -1) {
		                            vm.organsBox.content[index] = data;
		                        }
	                		}
							if(args.modal){
								args.modal.close();
							}
	                    }).catch(function(error) {
	                    	if(error && error.data && error.data.message === 'Organ inactive'){
								vm.alerts.push({ msg: $filter('translate')('global.errors.inactiveOrgan') });
							}else{
								vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'modifyOrgan', error)});
							}
	                    })
                	}else{
                		DialogsFactory.error('global.sec.literals.error_required_files_organ');
                	}
                }
            });
			
    		$scope.$on('annexaBoxOrgansModifyAgreementOrgan', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-proposal' && vm.isEdit) {
                	if(args.organ && args.organ.id && args.organ.organ && args.organ.organ.id){
	                	SecFactory.updateProposalOrgan(vm.proposal.id, args.organ.id, args.organ.organ.id, ((args.organ.delegationType && args.organ.delegationType.id)?args.organ.delegationType.id:undefined)).then(function(data) {
		                	if(data){
		                		var index = $linq(vm.organsBox.content).indexOf("x => x.id == " + data.id);
			                    if(index != -1) {
			                        vm.organsBox.content[index] = data;
									if(args.modifyLocalOrgan){
										args.modifyLocalOrgan(data);	
									}
			                    }
		                	}
							if(args.modal){
								args.modal.close();
							}
	                    }).catch(function(error) {
	                    	if(error && error.data && error.data.message === 'Organ inactive'){
								vm.alerts.push({ msg: $filter('translate')('global.errors.inactiveOrgan') });
							}else{
								vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'modifyOrgan', error)});
							}
	                    })
                	}else{
                		DialogsFactory.error('global.sec.literals.error_required_files_organ');
                	}
                }
            });
			
    		$scope.$on('annexaBoxOrgansDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-proposal' && vm.isEdit) {
                	if(args.removedIds && args.removedIds.length > 0){
	                	SecFactory.deleteProposalOrgan(vm.proposal.id, args.removedIds).then(function(data) {
	                        if(data) {
	                        	_.forEach(args.removedIds, function(id){
	                        		var index = $linq(vm.organsBox.content).indexOf("x => x.id == " + id);
	                                if(index != -1) {
	                                    vm.organsBox.content.splice(index,1);
	                                }
	                        	});
	                        	
	                        }
	                    }).catch(function(error) {
	                    	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'deleteOrgan', error)});
	                    })
                	}else{
                		DialogsFactory.error('global.sec.literals.error_required_files_removedIds');
                	}
                }
            });
    		
    		$scope.$on('annexaBoxUpdateSaveProposal', function (event, args) {
	            if(args.origin && args.origin == 'annexa-box-object-proposal') {
	            	vm.canEdit = ((vm.proposal && vm.proposal.state && _.contains(['PENDING', 'ACCEPTED'],vm.proposal.state))?true:false);
	            	if(vm.canEdit && vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE' && vm.proposal.state == 'ACCEPTED'){
	                	vm.canEdit = false;
	                }
	                HeaderService.changeState($state.current, true);
	                $state.reload();
	            }
	        });

   	        //endregion

    		//region Internal Communication Box
            $scope.$on('annexaBoxInternalCommunicationAdd', function (event, args) {
            	if(args.origin && args.origin == ('annexa-box-object-proposal')) {
            		vm.updateProposal(args.val, args.prop);
             	}
             });
             //endregion

            //region General Box

            var getEditPermissions = function(type) {
                switch (type) {
                    default:
                        var permissions = [];

                        if(AnnexaPermissionsFactory.havePermission(vm.editPerm)) {
                            permissions.push(vm.editPerm);
                        }

                        if(AnnexaPermissionsFactory.havePermission(vm.newPerm)) {
                            permissions.push(vm.newPerm);
                        }

                        return permissions;
                        break;
                }
            }

            //endregion 
            

            this.$onInit = function() {

            	var actualOrgan = vm.getActualProposalOrgan();
                if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active) {
					vm.isActiveCouncillor = true;
                }else{
					vm.isActiveCouncillor = false;
				}
            	vm.notificatorProfiles = vm.getNotificatorProfiles();
            	
            	if ($rootScope.esetMode && vm.notificatorProfiles) vm.notificatorResponsibles = vm.getNotificatorResponsibles(vm.notificatorProfiles[0]);
            	
            	if(vm.proposal && vm.proposal.footerClaims){
            		vm.proposal.footerClaimsAux = $linq(vm.proposal.footerClaims).select("x => x.footerClaim").toArray();
            	}
            	
            	// Aqui nos creamos el formulario para una propuesta.
                vm.proposalFormFields = [];
                vm.proposalFormFields.push({ type: 'field', id: 'proposalType', fieldType: 'select', data: GlobalDataFactory.proposalTypes, colClass: 'col-sm-12', required: true, label: 'global.sec.literals.proposalType', isReadOnly:vm.canEdit,
                		printFunction:  function(proposalType) {
                            if(proposalType) {
                            	return proposalType[vm.languageColumn];
                            } else {
                                return '';
                            }
                        }
                	});
                vm.proposalFormFields.push({ type: 'field', id: 'profile', fieldType: 'select', data: [], colClass: 'col-sm-12', required: true, label: 'global.sec.literals.created_profile', isReadOnly:false  });
                vm.proposalFormFields.push({ type: 'field', id: 'notificatorProfile', fieldType: 'select', data: vm.notificatorProfiles, colClass: 'col-sm-12', required: true, label: 'global.sec.literals.notificator_profile', isReadOnly:vm.canEdit, 
                		editFunction: $rootScope.esetMode ? vm.popupNotificatorResponsibles : undefined, 
                		printFunction:  function(notificatorProfile) {
                			if(notificatorProfile) {
                				return notificatorProfile[vm.languageColumn];
                            } else {
                            	return '';
                            }
                        }                	
                	});
                vm.proposalFormFields.push({ type: 'field', id: 'notificatorResponsible', fieldType: 'select', data: vm.notificatorResponsibles, labelProp: 'completeName', hideExpression: !$rootScope.esetMode, showIf: function(){return $rootScope.esetMode;}, colClass: 'col-sm-12', required: true, label: 'global.sec.literals.notificator_responsible', isReadOnly:vm.canEdit,
                		printFunction:  function(notificatorResponsible) {
                			if(notificatorResponsible) {
                				return notificatorResponsible.completeName;
                            } else {
                            	return '';
                            }
                        }                	
                	});
				vm.proposalFormFields.push({ type: 'field', id: 'addSecNotification', fieldType: 'select', data: [ { id: true, name: 'global.literals.yes' }, { id: false, name: 'global.literals.no' }], labelProp: 'name', colClass: 'col-sm-4', required: true, isReadOnly:vm.canEdit, label: 'global.sec.literals.addSecNotification',
                		printFunction:  function(addSecNotification) {
                            if(addSecNotification) {
                                return $filter('translate')('global.literals.yes');
                            } else {
                                return $filter('translate')('global.literals.no');
                            }
                        },
						showIf: function(){
							if(vm.canAddedSecConfiguration && vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype && vm.proposal.proposalType.proposalTypeSubtype === 'PROPOSAL' && vm.proposal.notificatorProfile && vm.proposal.notificatorProfile.id && vm.secretaryProfile && vm.proposal.notificatorProfile.id === vm.secretaryProfile.id){
								return true;
							}else{
								return false;
							}
						}
                	});
               vm.proposalFormFields.push({ type: 'field', id: 'addSecNotification', fieldType: 'annexaLabelButton', colClass: 'col-sm-8', buttonLabel: 'global.sec.literals.configureSecNotificacions',
                		executeFunction:  function() {
							var dossierCreated = $linq(vm.proposal.dossiers).firstOrDefault(undefined, "x=>x.dossier && x.dossier.id == "+vm.proposal.createdTransaction.dossierId);
							if(dossierCreated && dossierCreated.dossier && dossierCreated.dossier.procedure && dossierCreated.dossier.procedure.guided){
								TramNewFactory.configureSecNotifications(undefined, 'Proposal', vm.proposal.id, false, !vm.canEdit);	
							}else{
								TramNewFactory.configureSecNotifications(undefined, 'Proposal', vm.proposal.id, true, !vm.canEdit);	
							}
                        },
						hideExpression: function(){
							if(vm.canAddedSecConfiguration && vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype && vm.proposal.proposalType.proposalTypeSubtype === 'PROPOSAL' && vm.proposal.notificatorProfile && vm.proposal.notificatorProfile.id && vm.secretaryProfile && vm.proposal.notificatorProfile.id === vm.secretaryProfile.id){
								if(vm.proposal.addSecNotification){
									return false;	
								}else{
									return true;
								}
							}else{
								return true;
							}
						}
                	});
                vm.proposalFormFields.push({ type: 'field', id: 'anonymousThirds', fieldType: 'select', data: [ { id: true, name: 'global.literals.yes' }, { id: false, name: 'global.literals.no' }], labelProp: 'name', colClass: 'col-sm-12', required: true, isReadOnly:vm.canEdit, label: 'global.sec.literals.anonymReceiver',
                		printFunction:  function(anonymousThirds) {
                            if(anonymousThirds) {
                                return $filter('translate')('global.literals.yes');
                            } else {
                                return $filter('translate')('global.literals.no');
                            }
                        }
                	});
                vm.proposalFormFields.push({ type: 'field', id: 'urgent', fieldType: 'select', data: [ { id: true, name: 'global.literals.yes' }, { id: false, name: 'global.literals.no' }], labelProp: 'name', colClass: 'col-sm-12', required: true, isReadOnly:vm.canEdit, label: 'global.sec.literals.urgentProposal',
            		printFunction:  function(urgentProposal) {
                        if(urgentProposal) {
                            return $filter('translate')('global.literals.yes');
                        } else {
                            return $filter('translate')('global.literals.no');
                        }
                    }
            	});
                vm.proposalFormFields.push({ type: 'field', id: 'dateLimit', fieldType: 'date', colClass: 'col-sm-12', required: false, label: 'global.literals.deadlineDate', isReadOnly:false });
                vm.proposalFormFields.push({ type: 'field', id: 'footerClaimsAux', fieldType: 'select_multiple', colClass: 'col-sm-12 daughter-label-strong', data: GlobalDataFactory.footerClaims, label: 'global.sec.literals.footerClaims', isReadOnly:vm.canEdit,
                		printFunction:  function(footerClaims) {
                			var content = '';
                			if(footerClaims) {
                                _.forEach(footerClaims, function(footerClaim){
                                	content += ((content == '')?'':', ')+footerClaim[vm.languageColumn];
                                });
                            }
                			return content;
                        }
                	});
                vm.proposalFormFields.push({ type: 'field', id: 'addSecNotification', fieldType: 'annexaLabelButton', colClass: 'col-sm-8', buttonLabel: 'global.literals.validationCircuitObservations',
            		executeFunction:  function() {
                        var dataModal = {
                            row: true,
                            colClass: 'col-sm-12',
                            labelClass: ''
                        };
                        var modal = {
                            title: 'global.literals.validationCircuitObservations',
                            size: 'modal-md',
                            icon: '',
                            submitModal: function () {
                            },
                            alerts: []        
                        }
                        modal.annexaFormly = new AnnexaFormly();
                        
                        var fields = [];
                        if(vm.proposal.reviewObservations) {
                        	fields.push(modal.annexaFormly.createField('reviewDisagreements', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitReviewDisagreements',  vm.proposal.reviewDisagreement ? $filter("translate")("global.literals.yes") : $filter("translate")("global.literals.no")),dataModal));
                            fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitReviewObservations',  vm.proposal.reviewObservations),dataModal));
                        }
                        if(vm.proposal.validateObservations) {
                        	fields.push(modal.annexaFormly.createField('reviewDisagreements', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitValidateDisagreements',  vm.proposal.validateDisagreement ? $filter("translate")("global.literals.yes") : $filter("translate")("global.literals.no")),dataModal));
                            fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitValidateObservations',  vm.proposal.validateObservations),dataModal));
                        }
                        if(vm.proposal.acceptObservations) {
                        	fields.push(modal.annexaFormly.createField('reviewDisagreements', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitAcceptDisagreements',  vm.proposal.acceptDisagreement ? $filter("translate")("global.literals.yes") : $filter("translate")("global.literals.no")),dataModal));
                            fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitAcceptObservations',  vm.proposal.acceptObservations),dataModal));
                        }
                        modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                        modal.annexaFormly.options = {};
                        modal.annexaFormly.options.formState = {readOnly: false};
                        AnnexaFormlyFactory.showModal("validationCircuitObservationsModal", modal, undefined, false, true); 
                    },
					hideExpression: function(){            
						if(vm.proposal && (vm.proposal.reviewObservations || vm.proposal.validateObservations || vm.proposal.acceptObservations)){
							return false;	
						}else{
							return true;
						}
					}
            	});
                vm.proposalFormFields.push({ type: 'field', id: 'managementCenterObservations', fieldType: 'textarea', colClass: 'col-sm-12', required: false, label: 'global.sec.literals.observations_management_center', isReadOnly:false });
                vm.proposalFormFields.push({ type: 'field', id: 'secretariatObservations', fieldType: 'textarea', colClass: 'col-sm-12', required: false, label: 'global.sec.literals.observations_secretaria', isReadOnly:vm.canEdit });
                       
            	vm.newPerm = '';
            	vm.editPerm = '';
            	vm.isEdit = true;
            	
            	
                
                //region Init Boxs

                vm.organsBox = {
                    content: ((vm.proposal.organs)? vm.proposal.organs:[]),
                    new: {},
                    config: {proposal:vm.proposal, councillorId:((vm.isActiveCouncillor && vm.proposal.councillor && vm.proposal.councillor.id)?vm.proposal.councillor.id:undefined)},
                    origin: 'annexa-box-object-proposal'
                }
            
                $rootScope.$broadcast('dummy');

                vm.internalCommunicationBox = {
            		content: ((vm.proposal) ? vm.proposal:[]),
                    new: {},
                    config: {},
                    origin: 'annexa-box-object-proposal'
                }

                vm.documentBox = {
                    content: ((vm.proposal.documents)? $linq(vm.proposal.documents).toArray() : []),
                    config: { documentTitle: undefined },
                    origin: 'annexa-box-object-proposal'
                }


                vm.dossierBox = {
                    content: ((vm.proposal.dossiers)? $linq(vm.proposal.dossiers).select("x => x.dossier").toArray() : []),
                    config: {},
                    origin: 'annexa-box-object-proposal'
                }

                vm.decreeBox = {
                    content: ((SecFactory.decrees)? SecFactory.decrees : []),
                    config: {},
                    origin: 'annexa-box-object-proposal'
                }
				vm.operationsActive = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.active)?true:false);
				if(vm.operationsActive && vm.proposal && vm.proposal.createdTransaction && vm.proposal.createdTransaction.dossierId && vm.proposal.dossiers){
					var dossierCreated = $linq(vm.proposal.dossiers).firstOrDefault(undefined, "x=>x.dossier && x.dossier.id == "+vm.proposal.createdTransaction.dossierId);
					if(dossierCreated && dossierCreated.dossier && dossierCreated.dossier.procedure && dossierCreated.dossier.procedure.operationsActive){
						vm.operationsActive = true;
					}else{
						vm.operationsActive = false;
					}
				}else{
					vm.operationsActive = false;
				}
    			vm.userAccountInstances = TramNewFactory.userAccountInstances;
        		var proposalOperationsAndInvoices = [];
				if(vm.proposal && vm.proposal.operations && vm.proposal.operations.length > 0){
					proposalOperationsAndInvoices = $linq(vm.proposal.operations).select("x=>x.operation").toArray();
				}
	 			vm.proposalOperationsAndInvoicesBox = {
	            	boxTitle: 'global.operationsAndInvoices.title',
	                permissions: ((vm.userAccountInstances && vm.userAccountInstances.length > 0 && vm.isEdit)?{new:'process_dossier'}:{}),
	                content:  proposalOperationsAndInvoices,
	                config: { proposal: vm.proposal },
					onlyAddOperations: true,
					canRemoveOperations: (vm.userAccountInstances && vm.userAccountInstances.length > 0 && vm.isEdit),
					newTitle: 'global.literals.add',
	                origin: 'tram'
	            };
                //endregion
            }

            //region proposal Methods

            vm.reviewProposal = function() {
                var modal = angular.copy(SecModals.actionWithObservationProposal);
                modal.alerts = [];
                modal.title = 'global.literals.review';
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmReview = function () {
                	var newStatus = 'REVIWED';
                	if(vm.proposal && vm.proposal.proposalType && !vm.proposal.proposalType.circuitValidate && !vm.proposal.proposalType.circuitAccept) {
                		newStatus = 'ACCEPTED';
                	}
                	var reviewObservations = undefined;
                	var reviewDisagreement = undefined;
                	if(this.annexaFormly.model.modal_body.reason && this.annexaFormly.model.modal_body.reason != "") {
                		var reviewObservations = this.annexaFormly.model.modal_body.reason;
                		var reviewDisagreement = this.annexaFormly.model.modal_body.disagreement;
                	}
                	if(newStatus == 'ACCEPTED' && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE') {
                		vm.aproveProposalAction(reviewObservations, undefined, undefined, reviewDisagreement );
                	} else {
	                	SecFactory.updateStateProposal(vm.proposal.id, newStatus, reviewObservations, reviewDisagreement, "review").then(function(data) {
	                        if(data) {
	                        	vm.proposal.state = data.state;
	                        	$rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
	                        	$state.transitionTo('annexa.sec.proposals');
	                        }
	                    }).catch(function(error) {
	                    	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
	                    })
                	}
                    modal.close();
                };

                AnnexaFormlyFactory.showModal("modalReviewProposal", modal, confirmReview, false);
            
            };
            
            vm.validateProposal = function() {
                var modal = angular.copy(SecModals.actionWithObservationProposal);
                modal.alerts = [];
                modal.title = 'global.literals.validate';
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmValidate = function () {
                	var newStatus = 'VALIDATED';
                	if(vm.proposal && vm.proposal.proposalType && !vm.proposal.proposalType.circuitAccept) {
                		newStatus = 'ACCEPTED';
                	}
                	var validateObservations = undefined;
                	var validateDisagreement = undefined;
                	if(this.annexaFormly.model.modal_body.reason && this.annexaFormly.model.modal_body.reason != "") {
                		var validateObservations = this.annexaFormly.model.modal_body.reason;
                		var validateDisagreement = this.annexaFormly.model.modal_body.disagreement;
                	}
                	if(newStatus == 'ACCEPTED' && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE') {
                		vm.aproveProposalAction(undefined, validateObservations, undefined, undefined, validateDisagreement, undefined);
                	} else {
	                	SecFactory.updateStateProposal(vm.proposal.id, newStatus, validateObservations, validateDisagreement, "validate").then(function(data) {
	                        if(data) {
	                        	vm.proposal.state = data.state;
	                        	$rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
	                        	$state.transitionTo('annexa.sec.proposals');
	                        }
	                    }).catch(function(error) {
	                    	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
	                    })
                	}
                    modal.close();
                };

                AnnexaFormlyFactory.showModal("modalValidateProposal", modal, confirmValidate, false);
            };
            
            vm.aproveProposal = function() {
            	if(vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
            		if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
			                $rootScope.app.configuration.validation_circuit_secretary.value) {
                        var modal = angular.copy(SecModals.actionWithObservationProposal);
                        modal.alerts = [];
                        modal.title = 'global.literals.accept';
                        modal.annexaFormly.model = {};
                        modal.annexaFormly.model.modal_body = {};
                        modal.annexaFormly.options = {};
                        modal.annexaFormly.options.formState = {readOnly: false};

                        var confirmAccept = function () {
                        	var acceptObservations = undefined;
                    		var acceptDisagreement = this.annexaFormly.model.modal_body.disagreement;
                        	if(this.annexaFormly.model.modal_body.reason && this.annexaFormly.model.modal_body.reason != "") {
                        		var acceptObservations = this.annexaFormly.model.modal_body.reason;
                        	}
                        	vm.aproveProposalAction(undefined, undefined, acceptObservations, undefined, undefined, acceptDisagreement);
                            modal.close();
                        };

                        AnnexaFormlyFactory.showModal("modalAcceptProposal", modal, confirmAccept, false);
            		} else {
            			vm.aproveProposalAction();
            		}
            	} else {
            		vm.aproveProposalAction();
            	}
            }
            
            vm.aproveProposalAction = function(reviewObservations, validateObservations, acceptObservations, reviewDisagreement, validateDisagreement, acceptDisagreement) {
            	if(vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
            		var confirmMessage = 'global.sec.literals.createDecreeMessageConfirm';
                    if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sendTosignAcceptProposal 
                    		&& $rootScope.app.configuration.sendTosignAcceptProposal.value) {
                    	confirmMessage = 'global.sec.literals.createDecreeSignMessageConfirm';
                    }
            		
            		DialogsFactory.confirm('global.sec.literals.createDecreeMessage', confirmMessage).then(function (data) {
						var archiveClassificationId = undefined;
						var dossiersAuxprop = ((vm.proposal && vm.proposal.createdTransaction && vm.proposal.createdTransaction.id && vm.proposal.dossiers)?vm.proposal.dossiers:undefined);
						if(dossiersAuxprop && dossiersAuxprop.length > 0){
							_.forEach(dossiersAuxprop, function(dos){
								if(!archiveClassificationId && dos.dossier && dos.dossier.dossierTransactions && dos.dossier.dossierTransactions.length > 0){
									var dt = $linq(dos.dossier.dossierTransactions).firstOrDefault(undefined,"x => x.id =="+ vm.proposal.createdTransaction.id);
									if(dt){
										archiveClassificationId = ((dos.dossier && dos.dossier.archiveClassification && dos.dossier.archiveClassification.id)?dos.dossier.archiveClassification.id:undefined);
									}
								}
							});
						}
						//((vm.proposal && vm.proposal.createdTransaction && vm.proposal.createdTransaction.dossier && vm.proposal.createdTransaction.dossier.archiveClassification)?vm.proposal.createdTransaction.dossier.archiveClassification.id:undefined);
                		SecFactory.createDocumentModal('SDR', null, 'proposal', vm.proposal, true, undefined, undefined, archiveClassificationId)
                        .then(function(data) {
                            var selfData = data;
                            selfData.request.reviewObservations = reviewObservations;
                            selfData.request.validateObservations = validateObservations;
                            selfData.request.acceptObservations = acceptObservations;
                            selfData.request.reviewDisagreement = reviewDisagreement;
                            selfData.request.validateDisagreement = validateDisagreement;
                            selfData.request.acceptDisagreement = acceptDisagreement;
                            $http({
                                url: './api/sec/proposal/' + vm.proposal.id + '/decreee',
                                method: 'POST', 
                                data: JSOG.encode(selfData.request)
                            }).then(function(data) {
                            	selfData.modal.close();
                            	$rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
    	                        $state.transitionTo('annexa.sec.proposals');
                            }).catch(function (error) {
                            	if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
									var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
									DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
								}else if(error && error.data){
									DialogsFactory.error(ErrorFactory.getErrorMessage('proposal', 'send', error.data));
								}else{
				                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateDecree'));
								}
                            	selfData.modal.close();
                            });
                        }).catch(function(error) {
                            //Empty;
                        })
                   }).catch(function (data) {
        	               //Empty
        	       });
            	}else{
            		if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
			                $rootScope.app.configuration.validation_circuit_secretary.value) {
                        var modal = angular.copy(SecModals.actionWithObservationProposal);
                        modal.alerts = [];
                        modal.title = 'global.literals.accept';
                        modal.annexaFormly.model = {};
                        modal.annexaFormly.model.modal_body = {};
                        modal.annexaFormly.options = {};
                        modal.annexaFormly.options.formState = {readOnly: false};

                        var confirmAccept = function () {
                        	var validateObservations = undefined;
                        	var validateDisagreement = this.annexaFormly.model.modal_body.disagreement;
                        	if(this.annexaFormly.model.modal_body.reason && this.annexaFormly.model.modal_body.reason != "") {
                        		var validateObservations = this.annexaFormly.model.modal_body.reason;
                        	}
                        	SecFactory.updateStateProposal(vm.proposal.id, 'ACCEPTED', validateObservations, validateDisagreement, "accept").then(function(data) {
                                if(data) {
                                	vm.proposal.state = data.state;
                                	$rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
                                	$state.transitionTo('annexa.sec.proposals');
                                }
                            }).catch(function(error) {
                            	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
                            })       	
                            modal.close();
                        };

                        AnnexaFormlyFactory.showModal("modalAcceptProposal", modal, confirmAccept, false);
            		} else {
                		SecFactory.updateStateProposal(vm.proposal.id, 'ACCEPTED').then(function(data) {
    	                    if(data) {
    	                        vm.proposal.state = data.state;
    	                        $rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
    	                        $state.transitionTo('annexa.sec.proposals');
    	                    }
    	                }).catch(function(error) {
    	                	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
    	                })
            		}
            	}
            };
            
            vm.generateAmendment = function() {
                var modal = angular.copy(SecModals.amendmentProposal);
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmGenerateAmendment = function () {
                    if (this.annexaFormly.model.modal_body.reason) {
                    	var secretariatObservations = this.annexaFormly.model.modal_body.reason;
                    	DialogsFactory.confirm('global.sec.literals.amendmentPopupProposal', 'global.sec.literals.confirmAmendmentProposal').then(function (dataAux) {
                        	SecFactory.updateStateProposal(vm.proposal.id, 'AMENDMENT', secretariatObservations).then(function(data) {
                                if(data) {
                                	vm.proposal.state = data.state;
                                	$rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
                                	$state.transitionTo('annexa.sec.proposals');
                                }
                            }).catch(function(error) {
                            	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
                            })
                        }).catch(function (data) {
                        	//Empty
                        });        	
                        modal.close();
                    }
                };

                AnnexaFormlyFactory.showModal("modalAmendmentProposal", modal, confirmGenerateAmendment, false);
            };
            
            vm.cancelProposal = function() {
                var modal = angular.copy(SecModals.cancelProposal);
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmCancelProposal = function () {
                    if (this.annexaFormly.model.modal_body.reason) {
                    	var secretariatObservations = this.annexaFormly.model.modal_body.reason;
                    	DialogsFactory.confirm('global.sec.literals.cancelPopupProposal', 'global.sec.literals.confirmCancelProposal').then(function (dataAux) {
                        	SecFactory.updateStateProposal(vm.proposal.id, 'CANCELED', secretariatObservations).then(function(data) {
                                if(data) {
                                	vm.proposal.state = data.state;
                                	$rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
                                	$state.transitionTo('annexa.sec.proposals');
                                }
                            }).catch(function(error) {
                            	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
                            })
                        }).catch(function (data) {
                        	//Empty
                        });        	
                        modal.close();
                    }
                };

                AnnexaFormlyFactory.showModal("modalCancelProposal", modal, confirmCancelProposal, false);
            };
            
            vm.editDecreeDocument = function(){
            	if(vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE' && vm.proposal.decreeDocument){
    			   	DccumentsFactory.getEditOnlineUrl(vm.proposal.decreeDocument).then(function(data) {
                        window.location.href = data;
                    }).catch(function(error) {
                    	DialogsFactory.error('global.sec.literals.noDecreeDocument', 'Error');
                    });
            	}else{
            		DialogsFactory.error('global.sec.literals.noDecreeDocument', 'Error');
            	}
            }
            
            
            $scope.goBack = function(){
                $state.transitionTo('annexa.sec.proposals');
            };
            

        	vm.popupNotificatorResponsibles = function () {
                var dataModal = {
                    row: true,
                    colClass: 'col-sm-12',
                    labelClass: ''
                };
                var modal = {
                    title: 'global.tram.literals.selectResponsible',
                    size: 'modal-sm',
                    icon: '',
                    submitModal: function () {
                    },
                    alerts: []		
                }
                modal.annexaFormly = new AnnexaFormly();
                
                var saveProfilesAndResponsibles = function(){
                	if (modal.annexaFormly.model.modal_body.profile && modal.annexaFormly.model.modal_body.selectedResponsible) {
                		vm.proposal.notificatorProfile = modal.annexaFormly.model.modal_body.profile;
                		vm.proposal.notificatorResponsible = modal.annexaFormly.model.modal_body.selectedResponsible;
                		
                        RestService.update('./api/sec/proposal/' + vm.proposal.id, vm.proposal).then(function (data) {
                        	vm.proposal.proposalNumber = data.proposalNumber;
                        	if (vm.proposal.notificatorResponsible) vm.proposal.notificatorResponsible.completeName = vm.proposal.notificatorResponsible.name + ' ' + vm.proposal.notificatorResponsible.surename1 + ((vm.proposal.notificatorResponsible.surename2) ? ' ' + vm.proposal.notificatorResponsible.surename2 + '\n' : '\n');
                        	vm.getNotificatorResponsibles(vm.proposal.notificatorProfile);
                        	modal.close();
                        }).catch(function (error) {
                            console.error(error);
                        });
                	} else {
                		modal.alerts.push({msg: 'global.tram.errors.noProfileNoResponsibleSelected'});
                	} 
                }
                
                var fields = [];
                fields.push(modal.annexaFormly.createField('profile','annexaSelectResponsibleRow', '', new AnnexaFormlyFieldSelectTemplateOptions('global.tram.literals.selectProfileAndResponsible','id', vm.languageColumn, vm.notificatorProfiles, true), dataModal));
                fields[0].templateOptions.profileId = vm.proposal.notificatorProfile ? vm.proposal.notificatorProfile.id : undefined;
                fields[0].templateOptions.responsibleId = vm.proposal.notificatorResponsible ? vm.proposal.notificatorResponsible.id : undefined;
                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                AnnexaFormlyFactory.showModalPromise("updateNotificatorResponsible", modal, saveProfilesAndResponsibles, false, false, 'global.literals.select').then(function (modalAux) {
                    modal.modal = modalAux;
                    modal.close = modalAux.element.scope().close;
                    modalAux.element.modal();
                    angular.element(".modal-title").focus();
                }).catch(function () {
                    var a = 0;
                });
            
            }
        	
        	vm.updateProposal = function(val, prop) {
            	if(prop == 'anonymousThirds' || prop == 'urgent' || prop == 'addSecNotification') {
            	    vm.proposal[prop] = ((val && val.id)?val.id:undefined);
            	} else if(prop == 'notificatorProfile' || prop == 'notificatorResponsible') {
            		vm.proposal[prop] = ((val && val.id)?{id:val.id}:undefined);
            	} else if(prop == 'footerClaimsAux') {
            		 var footerClaims = [];
            		 _.forEach(val, function(fc){
            			 var fcAct = $linq(vm.proposal.footerClaims).where("x => x.footerClaim.id == "+fc.id).toArray();
            			 if(fcAct && fcAct.length > 0){
            				 footerClaims.push(fcAct[0]);
            			 }else{
            				 footerClaims.push({proposal:{id:vm.proposal.id}, footerClaim:{id:fc.id}});
            			 }
            		 });
            		 vm.proposal.footerClaims = footerClaims;
             	} else if(prop == 'internalCommunications') {
             		vm.proposal[prop] = [];
            		if(val) {
                		_.forEach(val,function(profile){
                			vm.proposal[prop].push({profile:{id:profile.id}});
                		});                			
            		}
                } else if(prop == 'transactionInternalProfilesAlert') {
                    vm.proposal[prop] = ((val && val.id)?{id:val.id}:((val)?{id:val}:undefined));
                } else {
                    vm.proposal[prop] = val;
                }

                RestService.update('./api/sec/proposal/' + vm.proposal.id, vm.proposal)
                     .then(function (data) {
                    	vm.proposal.proposalNumber = data.proposalNumber;
                    	if(prop == 'notificatorProfile') {
                    		vm.proposal[prop] = val;
                    	} else if (prop == 'notificatorResponsible') {
                    		vm.proposal[prop] = val;
                    		vm.getNotificatorResponsibles(data.notificatorProfile);
                    	}
                    	$rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
                     }).catch(function (error) {
                         console.error(error);
                });
            }
            
            vm.getActualProposalOrgan = function(){
                var organ = null;

                if(vm.proposal && vm.proposal.organs){
                    _.forEach(vm.proposal.organs, function(value){
                        if(value.actual){
                            organ = value;
                        }
                    });
                }
                return organ;
            }
            
        	vm.getNotificatorProfiles = function(){
            	vm.notificatorProfiles = [];
            	var notificatorProfilesTransaction = [];
            	var proposalDossierTransactionProp = SecFactory.proposalDossierTransaction;
            	if(proposalDossierTransactionProp && proposalDossierTransactionProp[0].dossierTransaction && proposalDossierTransactionProp[0].dossierTransaction.processProfiles){
            		notificatorProfilesTransaction = $linq(proposalDossierTransactionProp[0].dossierTransaction.processProfiles).select("x => x.profile").toArray();
                }
            	
            	var secretary = GlobalDataFactory.secretaryProfile;
            	if(!secretary){
            		secretary = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.secretary_profile);
            	}
            	if(notificatorProfilesTransaction) {
            		_.forEach(notificatorProfilesTransaction, function(notificatorProfileTransaction){
            			if(notificatorProfileTransaction && secretary 
            					&& notificatorProfileTransaction.id != secretary.id){
            				vm.notificatorProfiles.push(notificatorProfileTransaction);
                        }
                    });
            		if(secretary){
            			vm.notificatorProfiles.push(secretary);
            		}
            	}
            	
            	return vm.notificatorProfiles;
        	}
        	
        	vm.getNotificatorResponsibles = function(notificatorProfile){
            	vm.notificatorResponsibles = [];
            	
                if (notificatorProfile && notificatorProfile.userProfiles) {
                    angular.forEach(notificatorProfile.userProfiles, function (userProfile) {
                    	if (userProfile && userProfile.user && userProfile.user.name) {
                    		userProfile.user.completeName = userProfile.user.name + ' ' + userProfile.user.surename1 + ((userProfile.user.surename2) ? ' ' + userProfile.user.surename2 + '\n' : '\n');
                    		vm.notificatorResponsibles.push(userProfile.user);
                    	}
                    });
                }	
            	
            	return vm.notificatorResponsibles;
        	}
            //endregion
            
        }],
        bindings: {
            redirectToList: '=',
            proposal: '=?',
            preloadBoxes: '=?'
        }
    })
    .component('annexaBoxObjectProposalHeader',{
        templateUrl: './components/sec/annexa-box-object-proposal/annexa-box-object-proposal-header.html',
        require: {
            proposalComponent: '^^annexaBoxObjectProposal'
        },
        controller:['$rootScope', '$filter', 'Language', 'SecFactory', '$scope', function($rootScope, $filter, Language, SecFactory, $scope) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.urgent = SecFactory.proposal.urgent;
            $scope.$on('annexaBoxUpdateSaveProposal', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-proposal') {
                	var state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
                	if(vm.proposalComponent.proposal && vm.proposalComponent.proposal.state){
                		state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == '"+vm.proposalComponent.proposal.state+"'");
                	}
                	vm.state = state;
                	vm.canEdit = ((vm.proposalComponent.proposal && vm.proposalComponent.proposal.state && _.contains(['PENDING', 'ACCEPTED'],vm.proposalComponent.proposal.state))?true:false);
                	if(vm.canEdit && vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE' && vm.proposal.state == 'ACCEPTED'){
                    	vm.canEdit = false;
                    }
                    
                }
            });
            
            this.$onInit = function() {
            	vm.state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
            	if(vm.proposalComponent.proposal && vm.proposalComponent.proposal.state){
            		vm.state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == '"+vm.proposalComponent.proposal.state+"'");
           		}
            	vm.canEdit = ((vm.proposalComponent.proposal && vm.proposalComponent.proposal.state && _.contains(['PENDING', 'ACCEPTED'],vm.proposalComponent.proposal.state))?true:false);
            	if(vm.canEdit && vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE' && vm.proposal.state == 'ACCEPTED'){
                	vm.canEdit = false;
                }
                
            }
        }]
    })
    .component('annexaBoxObjectProposalTabs',{
        templateUrl: './components/sec/annexa-box-object-proposal/annexa-box-object-proposal-tabs.html',
        controller:['$scope', '$rootScope', 'HeaderService', '$state', 'TableFilterFactory', '$filter', 'Language', 'SecFactory', 'SecModals', 'AnnexaFormlyFactory', 'RouteStateFactory', 'DialogsFactory', 'GlobalDataFactory', 'apiAdmin', '$http', 'HelperService','RestService', '$stateParams', 'ErrorFactory', 'AnnexaPermissionsFactory', function($scope, $rootScope, HeaderService, $state, TableFilterFactory, $filter, Language, SecFactory, SecModals, AnnexaFormlyFactory, RouteStateFactory, DialogsFactory, GlobalDataFactory, apiAdmin, $http, HelperService,RestService,$stateParams, ErrorFactory, AnnexaPermissionsFactory) {
        	var activeTab;
        	$scope.validationCircuitSecretary = false;
        	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
                    $rootScope.app.configuration.validation_circuit_secretary.value) {
        		$scope.validationCircuitSecretary = true;
        	}
        	if(this.type && this.type != '') {
        		activeTab = this.type;
        	}
        	$scope.$on('annexaAdminBaseReloadTable', function(event, args) {
                $scope.tableDefinition.reloadInternalData(false);
            });

        	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sec_proposal_limits) {
        		$scope.proposalReviewAndValidateLimit = $rootScope.app.configuration.sec_proposal_limits.proposalReviewAndValidateLimit;
        		$scope.proposalAcceptLimit = $rootScope.app.configuration.sec_proposal_limits.proposalAcceptLimit;
        	}
        	$scope.reviewObservations = [];
            $scope.alerts = [];
            $scope.filterData = {
                    loggedUser: $rootScope.LoggedUser.id
            };
            if(activeTab) {
            	$scope.filterData.activeTab = activeTab;
            }
            $scope.filterUrgent = ''; 
            HeaderService.onChangeState($scope, function(message) {
                if(message.state.name == 'annexa.sec.proposals') { 

                    $rootScope.subHeadButtons = [
                        new HeadButtonSearch("#tableFilter")
                    ]
    				$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#proposals-list', 'fa fa-download', 'global.literals.export', undefined, 'exportList'));
                    if(activeTab && activeTab != 'all'){
                    	if(activeTab == 'review') {
                    		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#proposals-list', 'fa fa-check fa', 'global.sec.streamline.reviewSelected', undefined, 'reviewSelectedsList').setPermissions(['view_proposals','review_proposals']));
                    	}
                    	if(activeTab == 'validate') {
                    		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#proposals-list', 'fa fa-check fa', 'global.sec.streamline.validatedSelected', undefined, 'validatedSelectedsList').setPermissions(['view_proposals','valid_proposals']));
                    	}
                    	if(activeTab == 'accept') {
                    		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#proposals-list', 'fa fa-check fa', 'global.sec.streamline.acceptSelected', undefined, 'acceptSelectedsList').setPermissions(['view_proposals','accept_proposals']));
                    	}
                    }else{
                    	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#proposals-list', 'fa fa-check fa', 'global.sec.streamline.reviewSelected', undefined, 'reviewSelectedsList').setPermissions(['view_proposals','review_proposals']));
                    	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#proposals-list', 'fa fa-check fa', 'global.sec.streamline.validatedSelected', undefined, 'validatedSelectedsList').setPermissions(['view_proposals','valid_proposals']));
                    	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#proposals-list', 'fa fa-check fa', 'global.sec.streamline.acceptSelected', undefined, 'acceptSelectedsList').setPermissions(['view_proposals','accept_proposals']));
                    }
                }
            });

            HeaderService.changeState($state.current);

            $scope.proposalStates = angular.copy(SecFactory.proposalStates);
            if(activeTab && activeTab != 'all') {
            	$scope.proposalStates = [];
            }
            if(!$scope.validationCircuitSecretary) {
            	var indexSate = $linq($scope.proposalStates).indexOf("x => x.id == 'REVIWED'");
            	if(indexSate >= 0){
            		$scope.proposalStates.splice(indexSate,1);
            	}
            	indexSate = $linq($scope.proposalStates).indexOf("x => x.id == 'VALIDATED'");
            	if(indexSate >= 0){
            		$scope.proposalStates.splice(indexSate,1);
            	}
            }
            $scope.proposalStatesHeader = angular.copy($scope.proposalStates);
            $scope.proposalStatesHeader.unshift({
                id: '',
                icon: 'minus',
                style: 'text-black',
                name: $filter('translate')('global.literals.all'),
                orderStatus: 0
            });
            $scope.proposalTypeSubtypes = angular.copy(apiAdmin.proposalTypeSubtypes);
            /*$scope.proposalTypeSubtypes.unshift({
                id: '',
                icon: 'minus',
                style: 'text-black',
                description: $filter('translate')('global.literals.all'),
                orderStatus: 0
            });*/
            $scope.setFilterUrgent = function(tipus){
                if(tipus == 'urgents'){
                    $scope.filterUrgent = 'true';
                }else if(tipus == 'nourgents'){
                    $scope.filterUrgent = 'false';
                }else{
                    $scope.filterUrgent = '';
                }
                RouteStateFactory.addRouteState($state.current, $scope.filterUrgent, 'ColumnsFilter');
                $scope.tableDefinition.reloadInternalData(callbackInstance1,true);
                HeaderService.changeState($state.current);
            };
            var organFilter = angular.copy(GlobalDataFactory.organs);
            organFilter.push({
            	id: -1,
                language1: 'Decret',
                language2: 'Decreto',
                language3: 'NotTranslated'
            });
            var procedureFilter = angular.copy(GlobalDataFactory.procedures);
            var profileFilter = angular.copy(GlobalDataFactory.allProfiles);
            var sectionFilter = angular.copy(GlobalDataFactory.sections);
            var procedureProposalTypeFilter = angular.copy(GlobalDataFactory.procedureProposalType);
            $scope.filter = [
            	{ id: 'state', type: 'select-multiple', order: 2, label: 'global.literals.state', dataType: 'LOCAL', data: $scope.proposalStates, addAll: false, nameProperty: 'name', callAux: true, preFilter:(($stateParams.status)?$stateParams.status:HelperService.getPrefilter('secretary','tableProposals','state',$scope.proposalStates,'id')), negated:(($stateParams.status)?false:HelperService.getPrefilterNegated('secretary','tableProposals','state')) },
            	{ id: 'proposalType', type: 'select', order: 11, label: 'global.sec.literals.proposalSubType', dataType: 'LOCAL', data:$scope.proposalTypeSubtypes , addAll: true, nameProperty: 'description', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','proposalType',$scope.proposalTypeSubtypes,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','proposalType')  },
                { id: 'proposalNumber', type: 'text', order: 0, label: 'global.sec.literals.proposalNumber', preFilter:HelperService.getPrefilter('secretary','tableProposals','proposalNumber'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','proposalNumber')  },
                { id: 'extract', type: 'text', order: 7, label: 'global.literals.title', preFilter:HelperService.getPrefilter('secretary','tableProposals','extract'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','extract') },
                { id: 'dossier', type: 'text', order: 1, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','dossier'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','dossier')  },
                { id: 'organ', type: 'select', order: 3, label: 'global.sec.literals.organ', dataType: 'LOCAL', data: organFilter, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','organ',GlobalDataFactory.organs,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','organ') },
                { id: 'organSessionNumber', type: 'text', order: 6, label: 'global.sec.literals.organSessionNumber', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','organSessionNumber'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','organSessionNumber')  },
                { id: 'senderUser', type: 'text', order: 5, label: 'global.sec.literals.senderUser', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','senderUser'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','senderUser')  },
                { id: 'sendDate', type: 'dateRange', order: 10, label: 'global.sec.literals.sendDate', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','sendDate'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','sendDate')  },
                { id: 'section', type: 'select', order: 12, label: 'global.literals.section', dataType: 'LOCAL', data: sectionFilter , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','section',GlobalDataFactory.sections,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','section')  },
                { id: 'profile', type: 'select-multiple', order: 13, label: 'global.literals.profile', dataType: 'LOCAL', data: profileFilter, addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','profile',GlobalDataFactory.allProfiles,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','profile') },
            	{ id: 'procedure', type: 'select', order: 14, label: 'global.literals.procedure', dataType: 'LOCAL', data: procedureFilter , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','procedure',GlobalDataFactory.procedures,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','procedure')  },
            	{ id: 'procedureProposalType', type: 'select', order: 15, label: 'global.sec.literals.procedureProposalType', dataType: 'LOCAL', data: procedureProposalTypeFilter , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','procedureProposalType',GlobalDataFactory.procedureProposalType,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','procedureProposalType')  }
            ];
     		if($stateParams && $stateParams.filterId){
    			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
    				HelperService.getFilterByFilterId($scope.filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
    				RouteStateFactory.addRouteState($state.current, $scope.filter);
    			}
    		}else{
    		    var routeState = RouteStateFactory.getRouteState($state.current);
    	        if(routeState) {
    	            $scope.filter = routeState.state;
    	        }
    		}
            if(activeTab && activeTab != 'all') {
            	var indexState = $linq($scope.filter).indexOf("x => x.id == 'state'");
            	if(indexState >= 0) {
            		$scope.filter.splice(indexState, 1);
            	}
            } else {
            	var stateFilter = $linq($scope.filter).firstOrDefault(undefined, "x => x.id == 'state'");
            	if(!stateFilter) {
            		$scope.filter.unshift({ id: 'state', type: 'select-multiple', order: 2, label: 'global.literals.state', dataType: 'LOCAL', data: $scope.proposalStates, addAll: false, nameProperty: 'name', callAux: true, preFilter:(($stateParams.status)?$stateParams.status:HelperService.getPrefilter('secretary','tableProposals','state',$scope.proposalStates,'id')), negated:(($stateParams.status)?false:HelperService.getPrefilterNegated('secretary','tableProposals','state')) });
            	}
            }
            var getFilterCall = function() {
            	var filterCall = TableFilterFactory.getFilterCall($scope.filter);
            	if($scope.filterUrgent){
            		filterCall.urgent = $scope.filterUrgent;
            	}
            	return filterCall;
            }

            var getFilterCallAux = function () {
            	var filter = TableFilterFactory.getFilterCallAux($scope.filter, true);
            	if($scope.changeStatusFromHeader){
            		var filterAux = $linq($scope.filter).singleOrDefault(undefined, "x => x.id == 'state'");
            		if($scope.filterData.state){
            			if(filterAux){
                			var status = $linq($scope.proposalStates).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
                			filter.state = [status];
               				filterAux.model = filter.state;        		
                		}else{
                			filter.state = undefined;
               		    	filterAux.model = {};
                		}
                	}else{
            			filter.state = undefined;
            		    if(filterAux){
            		    	filterAux.model = {};
            		    }
            		}
            		$scope.changeStatusFromHeader = false;
            	}else if(filter.state && filter.state.length == 1){
            		if(filter.state[0].id != $scope.filterData.state){
            			$scope.filterData.state = filter.state[0].id;
            		}
            		
            	}else if(!filter.state || filter.state.length != 1){
            		$scope.filterData.state = undefined;
            	}
            	
            	if($scope.filterData.activeTab){
            		filter.proposalTab = $scope.filterData.activeTab;
            	}
            	
            	return filter;
            }

            var organRender = function(data, type, full, meta) {
                var content = '';

                if(!(full && full.proposalType && full.proposalType.proposalTypeSubtype && full.proposalType.proposalTypeSubtype == 'DECREE')){
    	            var actualOrgan = undefined;
    	            
    	            if($linq(data).count("x => x.actual == true") == 1) {
    	                actualOrgan = $linq(data).singleOrDefault(undefined, "x => x.actual == true");
    	            } else {
    	                var firstActual = $linq(data).firstOrDefault(undefined, "x => x.actual == true");
    	                if(firstActual && firstActual.id){
    	                	var indexFirstActual = $linq(data).indexOf("x => x.id == " + firstActual.id);
    	                	actualOrgan = data[indexFirstActual];
    	                }
    	            }
    	
    	            if(actualOrgan) {
    	                content += actualOrgan.organ[Language.getActiveColumn()];
    	            }
                } else if((full && full.proposalType && full.proposalType.proposalTypeSubtype && full.proposalType.proposalTypeSubtype == 'DECREE')) {
                	content = $filter('translate')('global.sec.literals.decree');
                }

                return content;
            }

            var organSessionNumberRender = function(data, type, full, meta) {
                var content = '';
                if(!(full && full.proposalType && full.proposalType.proposalTypeSubtype && full.proposalType.proposalTypeSubtype == 'DECREE')){
        	        if(data && data.length > 0) {
    	            	 var actualOrgan = undefined;
    	
    	                 if($linq(data).count("x => x.actual == true") == 1) {
    	                     actualOrgan = $linq(data).singleOrDefault(undefined, "x => x.actual == true");
    	                 } else {
    	                     var firstActual = $linq(data).firstOrDefault(undefined, "x => x.actual == true");
    	                     if(firstActual && firstActual.id){
    	                    	 var indexFirstActual = $linq(data).indexOf("x => x.id == " + firstActual.id);
    	                     	 actualOrgan = data[indexFirstActual];
    	                     }
    	                 }
    	
    	                 if(actualOrgan && actualOrgan.session && actualOrgan.session.sessionNumber){
    	                         content += actualOrgan.session.sessionNumber;
    	                 }
    	            }
                }
                return content;
            }
            
            var organSessionDateRender = function(data, type, full, meta) {
                var content = '';
                if(!(full && full.proposalType && full.proposalType.proposalTypeSubtype && full.proposalType.proposalTypeSubtype == 'DECREE')){
    	    	        
    	            if(data && data.length > 0) {
    	           	 var actualOrgan = undefined;
    	
    	                if($linq(data).count("x => x.actual == true") == 1) {
    	                    actualOrgan = $linq(data).singleOrDefault(undefined, "x => x.actual == true");
    	                } else {
    	                    var firstActual = $linq(data).firstOrDefault(undefined, "x => x.actual == true");
    	                    if(firstActual && firstActual.id){
    		                    var indexFirstActual = $linq(data).indexOf("x => x.id == " + firstActual.id);
    		                    actualOrgan = data[indexFirstActual];
    	                    }
    	                }
    	
    	                if(actualOrgan && actualOrgan.session && actualOrgan.session.sessionDateFirstConvene){
    	                     content += $filter('date')(new Date(actualOrgan.session.sessionDateFirstConvene), 'dd/MM/yyyy HH:mm');
    	                }
    	           }
               }
               return content;
            }
            
            var dossierRender = function(data, type, full, meta) {
                var content = '';
                if(data && data.dossier && data.dossier.dossierNumber) {
                	content = '<a class="text-primary" href="" ui-sref="annexa.tram.pending.viewdossier({dossier: '+data.dossier.id+', dossierTransaction: 0})" target="_blank">'+data.dossier.dossierNumber+'</a>';
                }
                return content;
            }
            
            $scope.headerIconPropStatus = function(icon) {
                if($scope.proposalStates && $scope.proposalStatesHeader.length > 0 && $scope.filterData.state) {
                    var status = $linq($scope.proposalStatesHeader).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");

                    if(status) {
                        if(icon) {
                            return status.icon;
                        } else {
                            return status.style;
                        }
                    }
                }

                if(icon) {
                    return 'minus';
                } else {
                    return 'false';
                }
            }
            
            var propStatusTitle = function() {
                var content = '';
     
                content += '<div>';
                content += '    <div class="btn-group dropdown">';
                content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
                content += '            <span class="fa-stack">';
                content += '                <i class="fa fa-{{headerIconPropStatus(true)}} {{headerIconPropStatus(false)}} text-lg fa-stack-1x" aria-hidden="true"></i>';
                content += '            </span>';
                content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
                content += '        </button>';
                content += '        <div class="dropdown-menu">';
                content += '            <a class="dropdown-item" ng-repeat="state in proposalStatesHeader | orderBy:\'orderStatus\'" ng-click="setProposalTypeDT(state);">';
                content += '                <span class="fa-stack">';
                content += '                    <i class="fa  fa-{{state.icon}} {{state.style}} text-lg fa-stack-1x"></i>';
                content += '                </span>';
                content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="state.id == \'\'"></span>';
                content += '                &nbsp;&nbsp;<span translate="{{state.name}}" ng-if="state.id != \'\'"></span>';
                content += '            </a>';
                content += '        </div>';
                content += '    </div>';
                content += '</div>';

                return content;
            }
            
            $scope.setProposalTypeDT = function(state){
                $scope.filterData.state = ((!state.id || state.id == 0) ?  '' : state.id);
                if($scope.tableDefinition){ 
                	$scope.changeStatusFromHeader = true;
                	$scope.tableDefinition.reloadInternalData(true, true);
                }else{
                    var scopeAux = angular.element('#proposals-list').scope();
                    if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                        scopeAux.tableDefinition.reloadInternalData(true, true);
                    }
                }
            }
            var divUrgent = function(data, type, full, meta){
                if(full.urgent){
                    var ci = new annexaCircleicon('warn','','','', 'sm','fa-exclamation text-right text-danger');
                    return ci.htmlObj.getHtml();
                }
                return '';
            }
            var propStatusRender = function(data, type, full, meta) {
                var content = '';

                if($scope.proposalStates && $scope.proposalStates.length > 0) {
                    var state = $linq($scope.proposalStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                    if(state) {
                        content += '<div class="text-center">';
                        content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                        content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                        content += '    </span>';
                        content += '</div>';
                    }
                }

                return content;
            }

            var showAproveProposal = function (data, type, full, meta) {
            	var checkStatus = 'PENDING';
            	if(full.proposalType){
            		if(full.proposalType.circuitValidate) {
            			checkStatus = 'VALIDATED';
            		} else if(full.proposalType.circuitReview) {
            			checkStatus = 'REVIWED';
            		}
            	}
            	if(AnnexaPermissionsFactory.havePermission(['view_proposals','accept_proposals'], true) && full.state && _.contains([checkStatus], full.state)){
            		if(full.proposalType && full.proposalType.proposalTypeSubtype == 'DECREE'){
            			return false;   			
            		}else{
            			if(full.organs != null && full.organs.length > 0) {
            				var canReview = false;
            				_.forEach(full.organs, function(organ){
            					if(organ.actual) {
            						if(organ.organ != null && organ.organ.acceptProposalUsers != null && organ.organ.acceptProposalUsers.length > 0) {
                            			_.forEach(organ.organ.acceptProposalUsers, function(rpu){
                            				if(rpu.user != null && rpu.user.id != null && rpu.user.id == $rootScope.LoggedUser.id) {
                            					canReview = true;
                            				}
                                    	});
                            		} else if(organ.organ != null && organ.organ.acceptProposalProfiles != null && organ.organ.acceptProposalProfiles.length > 0) {
                            			var userProf = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                            			_.forEach(organ.organ.acceptProposalProfiles, function(rpp){
                            				if(rpp.profile != null && rpp.profile.id != null && $linq(userProf).contains(rpp.profile.id)) {
                            					canReview = true;
                            				}
                                    	});
                            		} else {
                            			canReview = true;
                            		}
            					}
                        	});
                    		return canReview;
            			} else {
            				return true;
            			}
            		}
            	}else{
            		return false;
            	}
            }
            
            var showReviewProposal = function (data, type, full, meta) {
            	if(AnnexaPermissionsFactory.havePermission(['view_proposals','review_proposals'], true) && full.state && _.contains(['PENDING'], full.state)){
            		if(full.proposalType && !full.proposalType.circuitReview){
            			return false;   			
            		}else{
            			if(full.organs != null && full.organs.length > 0) {
            				var canReview = false;
            				_.forEach(full.organs, function(organ){
            					if(organ.actual) {
            						if(organ.organ != null && organ.organ.reviewProposalUsers != null && organ.organ.reviewProposalUsers.length > 0) {
                            			_.forEach(organ.organ.reviewProposalUsers, function(rpu){
                            				if(rpu.user != null && rpu.user.id != null && rpu.user.id == $rootScope.LoggedUser.id) {
                            					canReview = true;
                            				}
                                    	});
                            		} else if(organ.organ != null && organ.organ.reviewProposalProfiles != null && organ.organ.reviewProposalProfiles.length > 0) {
                            			var userProf = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                            			_.forEach(organ.organ.reviewProposalProfiles, function(rpp){
                            				if(rpp.profile != null && rpp.profile.id != null && $linq(userProf).contains(rpp.profile.id)) {
                            					canReview = true;
                            				}
                                    	});
                            		} else {
                            			canReview = true;
                            		}
            					}
                        	});
                    		return canReview;
            			} else {
            				return true;
            			}
            		}
            	}else{
            		return false;
            	}
            }
            
            var showValidateProposal = function (data, type, full, meta) {
            	var checkStatus = 'PENDING';
            	if(full.proposalType){
            		if(full.proposalType.circuitReview) {
            			checkStatus = 'REVIWED';
            		}
            	}
            	if(AnnexaPermissionsFactory.havePermission(['view_proposals','valid_proposals'], true) && full.state && _.contains([checkStatus], full.state)){
            		if(full.proposalType && !full.proposalType.circuitValidate){
            			return false;   			
            		}else{
            			if(full.organs != null && full.organs.length > 0) {
            				var canReview = false;
            				_.forEach(full.organs, function(organ){
            					if(organ.actual) {
            						if(organ.organ != null && organ.organ.validateProposalUsers != null && organ.organ.validateProposalUsers.length > 0) {
                            			_.forEach(organ.organ.validateProposalUsers, function(rpu){
                            				if(rpu.user != null && rpu.user.id != null && rpu.user.id == $rootScope.LoggedUser.id) {
                            					canReview = true;
                            				}
                                    	});
                            		} else if(organ.organ != null && organ.organ.validateProposalProfiles != null && organ.organ.validateProposalProfiles.length > 0) {
                            			var userProf = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                            			_.forEach(organ.organ.validateProposalProfiles, function(rpp){
                            				if(rpp.profile != null && rpp.profile.id != null && $linq(userProf).contains(rpp.profile.id)) {
                            					canReview = true;
                            				}
                                    	});
                            		} else {
                            			canReview = true;
                            		}
            					}
                        	});
                    		return canReview;
            			} else {
            				return true;
            			}
            		}
            	}else{
            		return false;
            	}
            }
            
            var showAproveDecree = function (data, type, full, meta) {
            	var checkStatus = 'PENDING';
            	if(full.proposalType){
            		if(full.proposalType.circuitValidate) {
            			checkStatus = 'VALIDATED';
            		} else if(full.proposalType.circuitReview) {
            			checkStatus = 'REVIWED';
            		}
            	}
            	if(AnnexaPermissionsFactory.havePermission(['view_proposals','accept_proposals'], true) && full.state && _.contains([checkStatus], full.state)){
            		if(full.proposalType && full.proposalType.proposalTypeSubtype == 'DECREE'){
            			return true;   			
            		}else{
            			return false;
            		}
            	}else{
            		return false;
            	}
            }
            
            var showAmendmentProposal = function (data, type, full, meta) {
            	if(AnnexaPermissionsFactory.havePermission(['amendment_proposals','view_proposals'], true) && full.state && _.contains(['PENDING','REVIWED','VALIDATED','ACCEPTED'], full.state)){
            		if(full.state == 'ACCEPTED' && full.proposalType && full.proposalType.proposalTypeSubtype == 'DECREE'){
            			return false;   			
            		}else{
            			return true;
            		}
            	}else{
            		return false;
            	}
            }
            
            var showCancelProposal = function (data, type, full, meta) {
            	if(AnnexaPermissionsFactory.havePermission(['cancel_proposals','view_proposals'], true) && full.state && _.contains(['PENDING','REVIWED','VALIDATED','ACCEPTED'], full.state)){
            		if(full.state == 'ACCEPTED' && full.proposalType && full.proposalType.proposalTypeSubtype == 'DECREE'){
            			return false;   			
            		}else{
            			return true;
            		}
            	}else{
            		return false;
            	}
            }
            
            function callbackInstance1(json) {
                console.log(json);
            };
            
            var divComment = function(data, type, proposal, meta){
            	var content = '';
            	if(proposal.reviewObservations || proposal.validateObservations || proposal.acceptObservations){
                	content += '<div class="text-center">';
                    content += '    <span class="fa-stack">';
                    content += '		<a href="" class="" ng-click="validationCircuitObservations('+proposal.id+')" role="button" data-animation="am-fade-and-slide-top">';
                    if(proposal.reviewDisagreement || proposal.validateDisagreement || proposal.acceptDisagreement){
                    	content += '        	<i class="fa text-danger text-lg fa-comment" ng-class="fa-comment"></i>';
                    } else {
                    	content += '        	<i class="fa text-grey text-lg fa-comment" ng-class="fa-comment"></i>';
                    }
                    content += '    	</a>';
                    content += '    </span>';
                    content += '</div>';
            	} else {
                	content += '<div class="text-center">';
                    content += '    <span class="fa-stack">';
                    content += '        <i class="fa text-grey text-lg fa-comment-o" ng-class="fa-comment-o"></i>';
                    content += '    </span>';
                    content += '</div>';
            	}
            	return content;
            }
            
            $scope.validationCircuitObservations = function(id){
            	var proposal = $scope.getRow(id,$scope.tableDefinition.list);
            	if(proposal) {
    				var dataModal = {
	                    row: true,
	                    colClass: 'col-sm-12',
	                    labelClass: ''
	                };
	                var modal = {
	                    title: 'global.literals.validationCircuitObservations',
	                    size: 'modal-md',
	                    icon: '',
	                    submitModal: function () {
	                    },
	                    alerts: []		
	                }
	                modal.annexaFormly = new AnnexaFormly();
	                
	                var fields = [];
	                if(proposal.reviewObservations) {
	                	fields.push(modal.annexaFormly.createField('reviewDisagreements', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitReviewDisagreements',  proposal.reviewDisagreement ? $filter("translate")("global.literals.yes") : $filter("translate")("global.literals.no")),dataModal));
	                	fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitReviewObservations',  proposal.reviewObservations),dataModal));
	                }
	                if(proposal.validateObservations) {
	                	fields.push(modal.annexaFormly.createField('reviewDisagreements', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitValidateDisagreements',  proposal.validateDisagreement ? $filter("translate")("global.literals.yes") : $filter("translate")("global.literals.no")),dataModal));
	                	fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitValidateObservations',  proposal.validateObservations),dataModal));
	                }
	                if(proposal.acceptObservations) {
	                	fields.push(modal.annexaFormly.createField('reviewDisagreements', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitAcceptDisagreements',  proposal.acceptDisagreement ? $filter("translate")("global.literals.yes") : $filter("translate")("global.literals.no")),dataModal));
	                	fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitAcceptObservations',  proposal.acceptObservations),dataModal));
	                }
	                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
	                modal.annexaFormly.options = {};
	                modal.annexaFormly.options.formState = {readOnly: false};
	                AnnexaFormlyFactory.showModal("validationCircuitObservationsModal", modal, undefined, false, true); 
            	}
			}
            
            $scope.addCommentsToArray=function(id){
                var row = $scope.getRow(id,$scope.tableDefinition.list);
                if(row){
                    $scope.reviewObservations['comment'+id] = row.reviewObservations;
                }
            };
            
            $scope.getRow=function(id,collection){
                for(var i=0;i<collection.length;i++){
                    if(collection[i].id==id) return collection[i];
                }
                return false;
            };
            
            $scope.showIconUrgentHeader = function(tipus){
                if(tipus == 'urgents' &&  $scope.filterUrgent == 'true'){
                    return true;
                }else if(tipus == 'nourgents' && $scope.filterUrgent == 'false'){
                    return true;
                }else if(tipus == 'tots' && $scope.filterUrgent == ''){
                    return true;
                }
                return false;
            };
            var searchUrgentDiv =  function() {
                var content = '';
                content += '<div>';
                content += '    <div class="btn-group dropdown">';
                content += '         <button class="btn btn-sm white dropdown-toggle  p-l-0" data-toggle="dropdown">';
                content += '            <span class="fa-stack">';
                content += '                <i ng-show="showIconUrgentHeader(\'tots\');" class=" text-lg fa fa-minus fa-stack-1x" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.all\' | translate}}</span>';
                content += '				<span class="fa-stack" ng-show="showIconUrgentHeader(\'urgents\');"><i class="fa fa-circle text-warn text-lg fa-stack-1x" aria-hidden="true"></i><i class="fa fa-exclamation text-danger fa-stack-1x" aria-hidden="true"></i><span class="sr-only">{{\'global.sign.urgents\' | translate}}</span></span>';
                content += '				<span class="fa-stack" ng-show="showIconUrgentHeader(\'nourgents\');"><i class="fa fa-circle text-grey-300 text-lg fa-stack-1x" aria-hidden="true"></i><i class="fa fa-exclamation text-white fa-stack-1x" aria-hidden="true"></i><span class="sr-only">{{\'global.sign.noUrgents\' | translate}}</span></span>';
                content += '            </span>';
                content += '        </button>';
                content += '        <div class="dropdown-menu">';
                content += '            <a class="dropdown-item" ng-click="setFilterUrgent(\'tots\');"><span class="fa-stack"><i class="fa text-lg fa-minus" aria-hidden="true"></i></span> {{\'global.literals.all\' | translate}}</a>';
                content += '            <a class="dropdown-item" ng-click="setFilterUrgent(\'urgents\');"><span class="fa-stack"><i class="fa fa-circle text-warn text-lg fa-stack-1x" aria-hidden="true"></i><i class="fa fa-exclamation text-danger fa-stack-1x" aria-hidden="true"></i></span> {{\'global.sign.urgents\' | translate}}</a>';
                content += '            <a class="dropdown-item" ng-click="setFilterUrgent(\'nourgents\');"><span class="fa-stack"><i class="fa fa-circle text-grey-300 text-lg fa-stack-1x" aria-hidden="true"></i><i class="fa fa-exclamation text-white fa-stack-1x" aria-hidden="true"></i></span> {{\'global.sign.noUrgents\' | translate}}</a>';
                content += '        </div>';
                content += '    </div>';
                content += '</div>';

                return content;
            };
            var actionButtons = [];
            if(activeTab && activeTab != 'all') {
            	if(activeTab == 'review') {
            		actionButtons.push(new ActionButton('global.literals.review','reviewProposal([data])',undefined, undefined, showReviewProposal));
            	}
            	if(activeTab == 'validate') {
            		actionButtons.push(new ActionButton('global.literals.validate','validateProposal([data])',undefined, undefined, showValidateProposal));
            	}
            	if(activeTab == 'accept') {
            		actionButtons.push(new ActionButton('global.literals.accept','aproveProposal([data])',undefined, undefined, showAproveProposal));
                	actionButtons.push(new ActionButton('global.literals.accept','aproveDecree([data])',undefined, undefined, showAproveDecree));
            	}
            	var permisionAmendment = AnnexaPermissionsFactory.havePermission(['amendment_proposals','view_proposals'], true);
            	var permisionCancel = AnnexaPermissionsFactory.havePermission(['cancel_proposals','view_proposals'], true);
            	if(permisionAmendment){
            		actionButtons.push(new ActionButton('global.sec.streamline.amendmentProposal','generateAmendment([data])',undefined, undefined, showAmendmentProposal));
            	}
            	if(permisionCancel){
            		actionButtons.push(new ActionButton('global.literals.cancel','cancelProposal([data])',undefined, undefined, showCancelProposal));
            	}
            } else {
            	actionButtons.push(new ActionButton('global.literals.accept','aproveProposal([data])',undefined, undefined, showAproveProposal));
            	actionButtons.push(new ActionButton('global.literals.validate','validateProposal([data])',undefined, undefined, showValidateProposal));
            	actionButtons.push(new ActionButton('global.literals.review','reviewProposal([data])',undefined, undefined, showReviewProposal));
            	actionButtons.push(new ActionButton('global.literals.accept','aproveDecree([data])',undefined, undefined, showAproveDecree));
            	actionButtons.push(new ActionButton('global.sec.streamline.amendmentProposal','generateAmendment([data])',undefined, undefined, showAmendmentProposal));
            	actionButtons.push(new ActionButton('global.literals.cancel','cancelProposal([data])',undefined, undefined, showCancelProposal));
            }
            //region selectedColumn
            $scope.proposalSelectedRows = [];
        	$scope.proposalRowSelected = {};
        	$scope.proposalSelectAll = false;
        	var addRouterState = function(){
        		var routerAux = {
					proposalSelectedRows: $scope.proposalSelectedRows,
					proposalRowSelected: $scope.proposalRowSelected,
					proposalSelectAll:$scope.proposalSelectAll
           	 	};
                RouteStateFactory.addRouteState($state.current, routerAux, 'ColumnsFilter');
        	}
        	
        	var initializeSelected = function () {
        		$scope.proposalSelectedRows = [];
        		$scope.proposalRowSelected = {};
        		$scope.proposalSelectAll = false;
        		addRouterState();
        		$scope.tableDefinition.reloadInternalData(true, true);
            }
        	$scope.reviewSelectedsList = function(){
        		var proposalsToReview = [];
            	var proposalsNotToReview = [];
        		if($scope.proposalSelectedRows && ($scope.proposalSelectedRows.length > 0 && ($scope.proposalReviewAndValidateLimit && $scope.proposalSelectedRows.length <= $scope.proposalReviewAndValidateLimit))){
        			var confirmBody = '';
                    confirmBody = $filter('translate')('global.sec.selecteds.confirmReviewProposalsListOk');
                    confirmBody += '<br/><ul>';
                    SecFactory.getSelectedProposalsList($scope.proposalSelectedRows, 'review').then(function(listSelected) {
                    	_.forEach(listSelected, function(proposal){	
                    		if(proposal.isProposalValidCircuit){
	                    		proposalsToReview.push(proposal);
	                    		confirmBody += '   <li>(' + proposal.proposalNumber + ') ' + proposal.extract +'</li>';
	                    	}else{
	                    		 proposalsNotToReview.push(proposal);
	                    	}
                       });
	                	confirmBody += '</ul>';
	                    if(proposalsNotToReview.length > 0) {
	                    	confirmBody += '<br/>';
	                        confirmBody += $filter('translate')('global.sec.selecteds.confirmReviewProposalsListKO');
	                        confirmBody += '<br/><ul>';
	                    	_.forEach(proposalsNotToReview, function(proposal){
	                    		confirmBody += '   <li>(' + proposal.proposalNumber + ') ' + proposal.extract +'</li>';
	            			});
	                        confirmBody += '</ul>';
	                    }
	                    DialogsFactory.confirm('global.sec.selecteds.confirmReviewProposalListTitle', confirmBody)
	                	.then(function() {
	                		if(proposalsToReview.length > 0){
	                			$scope.reviewProposals(proposalsToReview);
	                		}else{
	                			DialogsFactory.error($filter('translate')('global.sec.selecteds.selectedsNotValids'));
	                		}
	                	}).catch(function (data) {
	         	       });
                }).catch(function(error) {
                	
                })
        		}else if($scope.proposalSelectedRows && $scope.proposalSelectedRows.length > 0){
        			DialogsFactory.error($filter('translate')('global.sec.streamline.selectedMoreMaxLimit', {limit: $scope.proposalReviewAndValidateLimit}));
        		}else {
        			DialogsFactory.error($filter('translate')('global.sec.streamline.selectedsNoSelected'));
        		}
        	}
        	$scope.validatedSelectedsList = function(){
        		var proposalsToValidate = [];
            	var proposalsNotToValidate = [];
        		if($scope.proposalSelectedRows && ($scope.proposalSelectedRows.length > 0 && ($scope.proposalReviewAndValidateLimit && $scope.proposalSelectedRows.length <= $scope.proposalReviewAndValidateLimit))){
        			var confirmBody = '';
                    confirmBody = $filter('translate')('global.sec.selecteds.confirmValidateProposalsListOk');
                    confirmBody += '<br/><ul>';
                    SecFactory.getSelectedProposalsList($scope.proposalSelectedRows, 'validate').then(function(listSelected) {
                        _.forEach(listSelected, function(proposal){
                        	if(proposal.isProposalValidCircuit){
                        		proposalsToValidate.push(proposal);
                        		confirmBody += '   <li>(' + proposal.proposalNumber + ') ' + proposal.extract +'</li>';
                        	}else{
                        		proposalsNotToValidate.push(proposal);
                        	}
                        });
                        confirmBody += '</ul>';
                        if(proposalsNotToValidate.length > 0) {
                        	confirmBody += '<br/>';
                            confirmBody += $filter('translate')('global.sec.selecteds.confirmValidateProposalsListKO');
                            confirmBody += '<br/><ul>';
                        	_.forEach(proposalsNotToValidate, function(proposal){
                        		confirmBody += '   <li>(' + proposal.proposalNumber + ') ' + proposal.extract +'</li>';
                			});
                            confirmBody += '</ul>';
                        }
                        DialogsFactory.confirm('global.sec.selecteds.confirmValidateProposalListTitle', confirmBody)
                    	.then(function() {
                    		if(proposalsToValidate.length > 0){
                    			$scope.validateProposals(proposalsToValidate);
                    		}else{
                    			DialogsFactory.error($filter('translate')('global.sec.selecteds.selectedsNotValids'));
                    		}
                    	}).catch(function (data) {
             	       });
                    }).catch(function(error) {
                    	
                    })
        		}else if($scope.proposalSelectedRows && $scope.proposalSelectedRows.length > 0){
        			DialogsFactory.error($filter('translate')('global.sec.streamline.selectedMoreMaxLimit', {limit: $scope.proposalReviewAndValidateLimit}));
        		}else {
        			DialogsFactory.error($filter('translate')('global.sec.streamline.selectedsNoSelected'));
        		}
        	};
        	
        	$scope.acceptSelectedsList = function(){
        		var proposalsToAcceptProposal = [];
        		var proposalsToAcceptDecree = [];
            	var proposalsNotToAcceptProposal = [];
        		if($scope.proposalSelectedRows && ($scope.proposalSelectedRows.length > 0 && ($scope.proposalAcceptLimit && $scope.proposalSelectedRows.length <= $scope.proposalAcceptLimit))){
        			var confirmBody = '';
                    confirmBody = $filter('translate')('global.sec.selecteds.confirmAcceptProposalsListOk');
                    confirmBody += '<br/><ul>';
                    SecFactory.getSelectedProposalsList($scope.proposalSelectedRows, 'accept').then(function(listSelected) {
                    	 _.forEach(listSelected, function(proposal){
                     		if(proposal.isProposalValidCircuit && proposal.proposalType && proposal.proposalType.proposalTypeSubtype == 'DECREE'){
                     			proposalsToAcceptDecree.push(proposal);
                     			confirmBody += '   <li>(' + proposal.proposalNumber + ') ' + proposal.extract +'</li>';
                     		}else if(proposal.isProposalValidCircuit && proposal.proposalType && proposal.proposalType.proposalTypeSubtype != 'DECREE'){
                     			proposalsToAcceptProposal.push(proposal);
                     			confirmBody += '   <li>(' + proposal.proposalNumber + ') ' + proposal.extract +'</li>';
                     		}else if(!proposal.isProposalValidCircuit){
                     			proposalsNotToAcceptProposal.push(proposal);
                     		}
                         });
                         confirmBody += '</ul>';
                         if(proposalsNotToAcceptProposal.length > 0) {
                         	confirmBody += '<br/>';
                             confirmBody += $filter('translate')('global.sec.selecteds.confirmAcceptProposalsListKO');
                             confirmBody += '<br/><ul>';
                         	_.forEach(proposalsNotToAcceptProposal, function(proposal){
                         		confirmBody += '   <li>(' + proposal.proposalNumber + ') ' + proposal.extract +'</li>';
                 			});
                             confirmBody += '</ul>';
                         }
                         DialogsFactory.confirm('global.sec.selecteds.confirmAcceptProposalsListTitle', confirmBody)
                     	.then(function() {
                     		if(proposalsToAcceptProposal.length > 0 || proposalsToAcceptDecree.length > 0){
                     			$scope.aproveProposals(proposalsToAcceptProposal, proposalsToAcceptDecree);
                     		}else{
                     			DialogsFactory.error($filter('translate')('global.sec.selecteds.selectedsNotValids'));
                     		}
                        }).catch(function (data) {
             	       });
                    }).catch(function(error) {
                    	
                    })
        		}else if($scope.proposalSelectedRows && $scope.proposalSelectedRows.length > 0){
        			DialogsFactory.error($filter('translate')('global.sec.streamline.selectedMoreMaxLimit', {limit: $scope.proposalAcceptLimit}));
        		}else {
        			DialogsFactory.error($filter('translate')('global.sec.streamline.selectedsNoSelected'));
        		}
        	};
        	
        	
        	$scope.proposalSelectAllFunction = function(){
        		if($scope.proposalSelectAll){
            		SecFactory.getAllProposalIds(getFilterCall(), getFilterCallAux()).then(function(data) {
            			$scope.proposalSelectedRows.length = 0;
                    	if(data && data.length > 0) {
                    		_.forEach(data, function(dataId){
                    			$scope.proposalSelectedRows.push(dataId);
                    			if(!$scope.proposalRowSelected){
                    				$scope.proposalRowSelected = {};
                    			}
                    			$scope.proposalRowSelected[dataId] = true;
                    		});
                        }
                    	addRouterState();
                    }).catch(function (error) {
                    	$scope.proposalSelectAll = false;
                    });
            	}else{
            		$scope.proposalSelectedRows.length = 0;
                	if($scope.proposalRowSelected){
            			var keys = Object.keys($scope.proposalRowSelected);
                        _.forEach(keys, function(key){
                        	$scope.proposalRowSelected[key] = false;
                        });
            		}
                	addRouterState();
            	}
            }
        	
        	$scope.proposalSelectOne = function(id){
                var indexSelected = $linq($scope.proposalSelectedRows).indexOf(" x => x == " + id);

                if (indexSelected != -1) {
                	$scope.proposalSelectedRows.splice(indexSelected, 1);
                } else {
                	if($scope.tableDefinition.list){
                		var obj = $linq($scope.tableDefinition.list).firstOrDefault(undefined, "x => x.id == "+id);
    					if(obj){
    						$scope.proposalSelectedRows.push(obj.id);
    					}
                	}
                }
                if($scope.proposalSelectedRows && $scope.tableDefinition && $scope.tableDefinition.totalRecords && $scope.tableDefinition.totalRecords == $scope.proposalSelectedRows.length){
                	$scope.proposalSelectAll = true;
                }else{
                	$scope.proposalSelectAll = false;
                }
                addRouterState();
        	} 
        	
            var columnsAux = [
            	{ id: 'id', column: new SelectAllColumn($filter, 'proposalSelectAll', 'proposalSelectAllFunction', 'proposalSelectOne', 'proposalRowSelected', true, function(data){return true;}), sortable: false},
            	{ id: 'urgent', width: '56px', title: searchUrgentDiv(), render:divUrgent, sortable: false},
            	{ id: 'state', width: '1%', title: propStatusTitle(), render: propStatusRender, sortable: false },
                { id: 'proposalNumber', column: new TextColumn($filter, 'global.sec.literals.proposalNumber') },
                { id: 'extract', column:  new TextColumn($filter, 'global.literals.title') },
                { id: 'createdTransaction', title: $filter('translate')('global.literals.dossier'), render: dossierRender},
                { id: 'organs', title: $filter('translate')('global.sec.literals.organ'), render: organRender, sortable: false },
                { id: 'organs', title: $filter('translate')('global.sec.literals.organSessionNumber'), render: organSessionNumberRender, sortable: false },
                { id: 'organs', title: $filter('translate')('global.sec.literals.sessionDate'), render: organSessionDateRender, sortable: false },
                { id: 'senderUser', column:  new UserColumn($filter, 'global.sec.literals.senderUser') },
                { id: 'sendDate', width: '12%', column:  new DateColumn($filter, 'global.sec.literals.proposalSendDate') },
                { id: 'comments', title: '<div class="column-comments">' + $filter('translate')('global.literals.observations')+'</div>', render: divComment, sortable: false },
                { id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'), 
                		new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'), actionButtons),sortable: false }
            ];

            if(activeTab && activeTab != 'all') {
            	var indexColumn = $linq(columnsAux).indexOf("x => x.id == 'state'");
            	if(indexColumn >= 0) {
            		columnsAux.splice(indexColumn, 1);
            	}
            }
            
            if(!$scope.validationCircuitSecretary) {
            	var indexColumn = $linq(columnsAux).indexOf("x => x.id == 'comments'");
            	if(indexColumn >= 0) {
            		columnsAux.splice(indexColumn, 1);
            	}
            }
            
            var datatableSettings = $rootScope.app.configuration.datatables_settings;
            var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_sec_proposal', columnsAux);
            var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_sec_proposal', columnsAux);
            $scope.tableOrderProperties = {sortName: 'sendDate', sort: [[8,'desc']]};
            if(this.type){
            	$scope.tableOrderProperties = {sortName: 'sendDate', sort: [[9,'desc']]};
            }
            if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
                $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
            }
            $scope.tableDefinition = {
                id: 'tableProposals',
                origin: 'sec',
                objectType: 'Proposal',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: getFilterCall(),
                filterCallAux: getFilterCallAux(),
                filterCallFunc: getFilterCall,
                filterCallAuxFunc: getFilterCallAux,
                columns: columns,
                containerScope: $scope,
    			notAddExcel: true
            };

            $scope.viewElement = function(id){
                $state.transitionTo('annexa.sec.proposals.edit', { "proposal" : id});
            };
            
            $scope.reviewProposal = function(id) {
                var modal = angular.copy(SecModals.actionWithObservationProposal);
                modal.alerts = [];
                modal.title = 'global.literals.review';
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmReview = function () {
                	var newStatus = 'REVIWED';
                	var proposal = (($scope.tableDefinition && $scope.tableDefinition.list)?$linq($scope.tableDefinition.list).firstOrDefault(undefined, "x=> x.id == "+id):undefined);
                	if(proposal && proposal.proposalType && !proposal.proposalType.circuitValidate && !proposal.proposalType.circuitAccept) {
                		newStatus = 'ACCEPTED';
                	}
                	var reviewObservations = undefined;
                	var reviewDisagreement = undefined;
                	if(this.annexaFormly.model.modal_body.reason && this.annexaFormly.model.modal_body.reason != "") {
                		reviewObservations = this.annexaFormly.model.modal_body.reason;
                		reviewDisagreement = this.annexaFormly.model.modal_body.disagreement;
                	}
                	if(newStatus == 'ACCEPTED' && proposal.proposalType.proposalTypeSubtype == 'DECREE') {
                		$scope.aproveDecreeAction(id, reviewObservations, undefined, undefined, reviewDisagreement);
                	} else {
                    	SecFactory.updateStateProposal(id, newStatus, reviewObservations, reviewDisagreement,"review").then(function(data) {
                            if(data) {
                            	$scope.tableDefinition.reloadInternalData(true, true);
                            }
                        }).catch(function(error) {
                        	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
                        })    
                	}   	
                    modal.close();
                };

                AnnexaFormlyFactory.showModal("modalReviewProposal", modal, confirmReview, false);
            };
            
            $scope.reviewProposals = function(proposals) {
                var modal = angular.copy(SecModals.actionWithObservationProposal);
                modal.alerts = [];
                modal.title = 'global.literals.review';
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmReview = function () {
                	var newStatus = undefined;
                	var reviewProposals = [];
                	var decreeProposals = [];
                	var reviewObservations = undefined;
                	var reviewDisagreement = undefined;
                	if(this.annexaFormly.model.modal_body.reason && this.annexaFormly.model.modal_body.reason != "") {
                		reviewObservations = this.annexaFormly.model.modal_body.reason;
                		reviewDisagreement = this.annexaFormly.model.modal_body.disagreement;
                	}
                	_.forEach(proposals, function(proposal){
                		if(proposal && proposal.proposalType && !proposal.proposalType.circuitValidate && !proposal.proposalType.circuitAccept) {
                    		newStatus = 'ACCEPTED';
                    	}else{
                    		newStatus = 'REVIWED';
                    	}
                		if(newStatus == 'ACCEPTED' && proposal.proposalType.proposalTypeSubtype == 'DECREE') {
                			decreeProposals.push({id:proposal.id});
                    	}else{
                    		reviewProposals.push({id:proposal.id, proposalState:newStatus});
                    	}
                	});
                	
                	if(reviewProposals.length > 0) {
                    	SecFactory.updateStateProposals(reviewProposals, reviewObservations, reviewDisagreement, "review").then(function(data) {
                            if(data) {
                            	if(decreeProposals.length > 0){
                            		//Pendent de revisió de com es fa amb certificats
                            		$scope.aproveDecreesAction(decreeProposals, reviewObservations, undefined, undefined, reviewDisagreement);
                            	}else{
                            		$scope.tableDefinition.reloadInternalData(true, true);
                            	}
                            }
                        }).catch(function(error) {
                        	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
                        })
                	}else if(decreeProposals.length > 0){
                		//Pendent de revisió de com es fa amb certificats
                		$scope.aproveDecreesAction(decreeProposals, reviewObservations, undefined, undefined, reviewDisagreement);
                	}   	
                    modal.close();
                };

                AnnexaFormlyFactory.showModal("modalReviewProposals", modal, confirmReview, false);
            };
            
            $scope.validateProposal = function(id) {
                var modal = angular.copy(SecModals.actionWithObservationProposal);
                modal.alerts = [];
                modal.title = 'global.literals.validate';
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmValidate = function () {
                	var newStatus = 'VALIDATED';
                	var proposal = (($scope.tableDefinition && $scope.tableDefinition.list)?$linq($scope.tableDefinition.list).firstOrDefault(undefined, "x=> x.id == "+id):undefined);
                	if(proposal && proposal.proposalType && !proposal.proposalType.circuitAccept) {
                		newStatus = 'ACCEPTED';
                	}
                	var validateObservations = undefined;
                	var validateDisagreement = undefined;
                	if(this.annexaFormly.model.modal_body.reason && this.annexaFormly.model.modal_body.reason != "") {
                		validateObservations = this.annexaFormly.model.modal_body.reason;
                		validateDisagreement = this.annexaFormly.model.modal_body.disagreement;
                	}
                	if(newStatus == 'ACCEPTED' && proposal.proposalType.proposalTypeSubtype == 'DECREE') {
                		$scope.aproveDecreeAction(id, undefined, validateObservations, undefined, undefined, validateDisagreement);
                	} else {
                		SecFactory.updateStateProposal(id, newStatus, validateObservations, validateDisagreement, "validate").then(function(data) {
                            if(data) {
                            	$scope.tableDefinition.reloadInternalData(true, true);
                            }
                        }).catch(function(error) {
                        	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
                        })  
                	}     	
                    modal.close();
                };

                AnnexaFormlyFactory.showModal("modalValidateProposal", modal, confirmValidate, false);
            };
            
            $scope.validateProposals = function(proposals) {
                var modal = angular.copy(SecModals.actionWithObservationProposal);
                modal.alerts = [];
                modal.title = 'global.literals.validate';
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmValidate = function () {
                	var newStatus = undefined;
                	var validateProposals = [];
                	var decreeProposals = [];
                	var validateObservations = undefined;
                	var validateDisagreement = undefined;
                	if(this.annexaFormly.model.modal_body.reason && this.annexaFormly.model.modal_body.reason != "") {
                		validateObservations = this.annexaFormly.model.modal_body.reason;
                		validateDisagreement = this.annexaFormly.model.modal_body.disagreement;
                	}
                	_.forEach(proposals, function(proposal){
                		if(proposal && proposal.proposalType && !proposal.proposalType.circuitAccept) {
                    		newStatus = 'ACCEPTED';
                    	}else{
                    		newStatus = 'VALIDATED';
                    	}
                		if(newStatus == 'ACCEPTED' && proposal.proposalType.proposalTypeSubtype == 'DECREE') {
                			decreeProposals.push({id:proposal.id});
                    	}else{
                    		validateProposals.push({id:proposal.id, proposalState:newStatus});
                    	}
                	});
                	
                	if(validateProposals.length > 0) {
                    	SecFactory.updateStateProposals(validateProposals, validateObservations, validateDisagreement, "validate").then(function(data) {
                            if(data) {
                            	if(decreeProposals.length > 0){
                            		//Pendent de revisió de com es fa amb certificats
                            		$scope.aproveDecreesAction(decreeProposals, undefined, validateObservations, undefined, undefined, validateDisagreement);
                            	}else{
                            		$scope.tableDefinition.reloadInternalData(true, true);
                            	}
                            }
                        }).catch(function(error) {
                        	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
                        })    
                	}else if(decreeProposal.length > 0){
                		//Pendent de revisió de com es fa amb certificats
                		$scope.aproveDecreesAction(decreeProposals, undefined, validateObservations, undefined, undefined, validateDisagreement);
                	}
                	     	
                    modal.close();
                };

                AnnexaFormlyFactory.showModal("modalValidateProposal", modal, confirmValidate, false);
            };
            
            $scope.aproveProposal = function(id) {
        		if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
		                $rootScope.app.configuration.validation_circuit_secretary.value) {
                    var modal = angular.copy(SecModals.actionWithObservationProposal);
                    modal.alerts = [];
                    modal.title = 'global.literals.accept';
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};

                    var confirmAccept = function () {
                    	var validateObservations = undefined;
                    	var validateDisagreement = undefined;
                    	if(this.annexaFormly.model.modal_body.reason && this.annexaFormly.model.modal_body.reason != "") {
                    		validateObservations = this.annexaFormly.model.modal_body.reason;
                    		validateDisagreement = this.annexaFormly.model.modal_body.disagreement;
                    	}
                    	SecFactory.updateStateProposal(id, 'ACCEPTED', validateObservations, validateDisagreement, "accept").then(function(data) {
                            if(data) {
                            	$scope.tableDefinition.reloadInternalData(true, true);
                            }
                        }).catch(function(error) {
                        	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
                        })       	
                        modal.close();
                    };

                    AnnexaFormlyFactory.showModal("modalAcceptProposal", modal, confirmAccept, false);
        		} else {
            		SecFactory.updateStateProposal(id, 'ACCEPTED').then(function(data) {
                        if(data) {
                        	$scope.tableDefinition.reloadInternalData(true, true);
                        }
                    }).catch(function(error) {
                    	DialogsFactory.notify('global.errors.unknownUpdate', $filter('translate')('DIALOGS_ERROR_MSG'));
                    })
        		}
            };
            
            $scope.aproveProposals = function(proposals, decrees) {
        		if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
		                $rootScope.app.configuration.validation_circuit_secretary.value) {
                    var modal = angular.copy(SecModals.actionWithObservationProposal);
                    modal.alerts = [];
                    modal.title = 'global.literals.accept';
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};
                    var acceptProposals = [];
                    var acceptDecrees = [];

                    var confirmAccept = function () {
                    	var acceptObservations = undefined;
                    	var acceptDisagreement = undefined;
                    	if(this.annexaFormly.model.modal_body.reason && this.annexaFormly.model.modal_body.reason != "") {
                    		acceptObservations = this.annexaFormly.model.modal_body.reason;
                    		acceptDisagreement = this.annexaFormly.model.modal_body.disagreement;
                    	}
                    	_.forEach(proposals, function(proposal){
                    		acceptProposals.push({id:proposal.id, proposalState:'ACCEPTED'});
                    	});
                    	_.forEach(decrees, function(decree){
                    		acceptDecrees.push({id:decree.id});
                    	});
                    	
                    	if(acceptProposals.length > 0){
                    		SecFactory.updateStateProposals(acceptProposals, acceptObservations, acceptDisagreement, "accept").then(function(data) {
                                if(data) {
                                	if(acceptDecrees.length > 0){
                                		$scope.aproveDecreesAction(acceptDecrees, undefined, undefined, acceptObservations, undefined, undefined, acceptDisagreement);
                                		$scope.tableDefinition.reloadInternalData(true, true);
                                	}else{
                                		$scope.tableDefinition.reloadInternalData(true, true);
                                	}	
                                }
                            }).catch(function(error) {
                            	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
                            })
                    	}else if(acceptDecrees.length > 0){
                    		$scope.aproveDecreesAction(acceptDecrees, undefined, undefined, acceptObservations, undefined, undefined, acceptDisagreement);
                    	}
                    	       	
                        modal.close();
                    };

                    AnnexaFormlyFactory.showModal("modalAcceptProposal", modal, confirmAccept, false);
        		} else {
        			_.forEach(proposals, function(proposal){
                		acceptProposals.push({id:proposal.id});
                	});
        			_.forEach(decrees, function(decrees){
                		acceptDecrees.push({id:decrees.id});
                	});
        			if(acceptProposals.length > 0){
        				SecFactory.updateStateProposals(acceptProposals).then(function(data) {
                            if(data) {
                            	if(acceptDecrees.length > 0){
                            		$scope.aproveDecreesAction(acceptDecrees);
                            	}else{
                            		$scope.tableDefinition.reloadInternalData(true, true);
                            	}
                            }
                        }).catch(function(error) {
                        	DialogsFactory.notify('global.errors.unknownUpdate', $filter('translate')('DIALOGS_ERROR_MSG'));
                        })
        			}else if(acceptDecrees.length > 0){
                		$scope.aproveDecreesAction(acceptDecrees);
                	}
            		
        		}
            };
            
            $scope.aproveDecree = function(id) {
        		if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
		                $rootScope.app.configuration.validation_circuit_secretary.value) {
                    var modal = angular.copy(SecModals.actionWithObservationProposal);
                    modal.alerts = [];
                    modal.title = 'global.literals.accept';
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};

                    var confirmAccept = function () {
                    	var acceptObservations = undefined;
                		acceptDisagreement = this.annexaFormly.model.modal_body.disagreement;
                    	if(this.annexaFormly.model.modal_body.reason && this.annexaFormly.model.modal_body.reason != "") {
                    		acceptObservations = this.annexaFormly.model.modal_body.reason;
                    	}
                    	$scope.aproveDecreeAction(id, undefined, undefined, acceptObservations, undefined, undefined, acceptDisagreement);
                        modal.close();
                    };

                    AnnexaFormlyFactory.showModal("modalAcceptProposal", modal, confirmAccept, false);
        		} else {
        			$scope.aproveDecreeAction(id);
        		}
            };
            
            $scope.aproveDecreeAction = function(id, reviewObservations, validateObservations, acceptObservations, reviewDisagreement, validateDisagreement, acceptDisagreement) {
        		var confirmMessage = 'global.sec.literals.createDecreeMessageConfirm';
                if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sendTosignAcceptProposal 
                		&& $rootScope.app.configuration.sendTosignAcceptProposal.value) {
                	confirmMessage = 'global.sec.literals.createDecreeSignMessageConfirm';
                }
                
            	DialogsFactory.confirm('global.sec.literals.createDecreeMessage', confirmMessage)
                .then(function (data) {
    				var archiveClassificationId = undefined;
    				var prop = (($scope.tableDefinition && $scope.tableDefinition.list)?$linq($scope.tableDefinition.list).firstOrDefault(undefined, "x=> x.id == "+id):undefined);
    				if(prop && prop.createdTransaction && prop.createdTransaction.dossier && prop.createdTransaction.dossier.archiveClassification && prop.createdTransaction.dossier.archiveClassification.id){
    					archiveClassificationId = prop.createdTransaction.dossier.archiveClassification.id;
    				}				
            		SecFactory.createDocumentModal('SDR', null, undefined, {id:id}, true, undefined, undefined, archiveClassificationId)
                    .then(function(data) {
                        var selfData = data;
                        selfData.request.reviewObservations = reviewObservations;
                        selfData.request.validateObservations = validateObservations;
                        selfData.request.acceptObservations = acceptObservations;
                        selfData.request.reviewDisagreement = reviewDisagreement;
                        selfData.request.validateDisagreement = validateDisagreement;
                        selfData.request.acceptDisagreement = acceptDisagreement;
                        $http({
                            url: './api/sec/proposal/' + id + '/decreee',
                            method: 'POST', 
                            data: JSOG.encode(selfData.request)
                        }).then(function(data) {
                        	selfData.modal.close();
                        	if(data) {
                            	$scope.tableDefinition.reloadInternalData(true, true);
                            }
                        }).catch(function (error) {
                        	if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
    							var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
    							DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
    						}else if(error && error.data){
    							DialogsFactory.error(ErrorFactory.getErrorMessage('proposal', 'send', error.data));
    						}else {
    							DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateDecree'));
    						}
                        	selfData.modal.close();
                        });
                    }).catch(function(error) {
                        //Empty;
                    })
               }).catch(function (data) {
    	               //Empty
    	       });
            };
            
            $scope.aproveDecreesAction = function(decrees, reviewObservations, validateObservations, acceptObservations, reviewDisagreement, validateDisagreement, acceptDisagreement) {
        		var confirmMessage = 'global.sec.literals.createDecreesMessageConfirm';
                if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type 
                		&& $rootScope.app.configuration.councillor_proposal_type.active) {
                	confirmMessage = 'global.sec.literals.createDecreesSignMessageConfirm';
                }
            	DialogsFactory.confirm('global.sec.literals.createDecreesMessage', confirmMessage)
                .then(function (data) {
    				var decreesProposals = [];
    				var profileDecrees = undefined;
    				var profiles = $linq($rootScope.LoggedUser.userDefaultEntityProfiles).where("x => x.entityType == 'SEC'").select("x => x.profile").toArray();
                    if (profiles && profiles.length > 0) {
                    	profileDecrees = profiles[0].id;
                    }
                    var sectionDecrees = undefined;
                    var sections = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();
                    if(sections && sections.length == 1){
                    	sectionDecrees = sections[0].id;
                    }
    				_.forEach(decrees, function(decree){
    					decreesProposals.push({id:decree.id});
    				});
            		SecFactory.createDocumentModalDecrees('SDR', null, undefined, undefined, true, undefined, undefined, undefined)
                    .then(function(data) {
                        var selfData = data;
                        selfData.request.proposals = decreesProposals;
                        selfData.request.reviewObservations = reviewObservations;
                        selfData.request.validateObservations = validateObservations;
                        selfData.request.acceptObservations = acceptObservations;
                        selfData.request.reviewDisagreement = reviewDisagreement;
                        selfData.request.validateDisagreement = validateDisagreement;
                        selfData.request.acceptDisagreement = acceptDisagreement;
                        selfData.request.profile = ((profileDecrees)?{id:profileDecrees}:undefined);
                        selfData.request.section = ((sectionDecrees)?{id:sectionDecrees}:undefined);
                        $http({
                            url: './api/sec/proposal/decrees',
                            method: 'POST', 
                            data: JSOG.encode(selfData.request)
                        }).then(function(data) {
                        	selfData.modal.close();
                        	if(data) {
                            	$scope.tableDefinition.reloadInternalData(true, true);
                            }
                        }).catch(function (error) {
                        	if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
    							var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
    							DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
    						}else if(error && error.data){
    							DialogsFactory.error(ErrorFactory.getErrorMessage('proposal', 'send', error.data));
    						}else {
    							DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateDecree'));
    						}
                        	selfData.modal.close();
                        });
                    }).catch(function(error) {
                        //Empty;
                    })
               }).catch(function (data) {
    	               //Empty
    	       });
            };
            
            $scope.generateAmendment = function(id) {
                var modal = angular.copy(SecModals.amendmentProposal);
                $scope.proposalId = id;
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmGenerateAmendment = function () {
                    if (this.annexaFormly.model.modal_body.reason) {
                    	var secretariatObservations = this.annexaFormly.model.modal_body.reason;
                    	DialogsFactory.confirm('global.sec.literals.amendmentPopupProposal', 'global.sec.literals.confirmAmendmentProposal').then(function (dataAux) {
                        	SecFactory.updateStateProposal($scope.proposalId, 'AMENDMENT', secretariatObservations).then(function(data) {
                                if(data) {
                                	$scope.tableDefinition.reloadInternalData(true, true);
                                }
                            }).catch(function(error) {
                            	DialogsFactory.notify('global.errors.unknownUpdate', $filter('translate')('DIALOGS_ERROR_MSG'));
                            })
                        }).catch(function (data) {
                        	//Empty
                        });        	
                        modal.close();
                    }
                };

                AnnexaFormlyFactory.showModal("modalAmendmentProposal", modal, confirmGenerateAmendment, false);
            };
            
            $scope.cancelProposal = function(id) {
                var modal = angular.copy(SecModals.cancelProposal);
                $scope.proposalId = id;
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmCancelProposal = function () {
                    if (this.annexaFormly.model.modal_body.reason) {
                    	var secretariatObservations = this.annexaFormly.model.modal_body.reason;
                    	DialogsFactory.confirm('global.sec.literals.cancelPopupProposal', 'global.sec.literals.confirmCancelProposal').then(function (dataAux) {
                        	SecFactory.updateStateProposal($scope.proposalId, 'CANCELED', secretariatObservations).then(function(data) {
                                if(data) {
                                	$scope.tableDefinition.reloadInternalData(true, true);
                                }
                            }).catch(function(error) {
                            	DialogsFactory.notify('global.errors.unknownUpdate', $filter('translate')('DIALOGS_ERROR_MSG'));
                            })
                        }).catch(function (data) {
                        	//Empty
                        });        	
                        modal.close();
                    }
                };

                AnnexaFormlyFactory.showModal("modalCancelProposal", modal, confirmCancelProposal, false);
            };

    		$scope.exportList = function(){
    			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
                .then(function (data) {
    				RestService.exportData($scope.tableDefinition);
               }).catch(function (data) {
    	               //Empty
    	       });
    		}
            
        }],
        bindings: {
            type: '@'
        }
    })